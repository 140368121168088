'use strict';

export function get_readable_area(area) {
  let area_in_ha = area / (100*100);
  let fixed = 2;
  let unit_prefix = '';

  if (typeof Intl === 'undefined') {
    let area_text;
    if (area_in_ha > 1) {
      area_text = Math.round(area_in_ha);
    } else {
      area_text = Number.parseFloat(area_in_ha).toPrecision(4);
    }
    return area_text + ' ' + unit_prefix + 'ha';
  }

  if (area_in_ha < 0.01) {
    fixed = 4;
  }

  if (area_in_ha >= 1000) {
    fixed = 0;
  }

  // we used to convert thousands and millions of ha to Mha and Kha but this
  // was asked to be removed by Susan 2018-09-06. We will however do nice
  // grouping of digits. Note that this is very US centric since other locales
  // may use different character to separate thousands and may also group
  // digits differently
  let fmtopts = {
    minimumFractionDigits:fixed,
    maximumFractionDigits:fixed
  };

  let area_text = new Intl.NumberFormat('en-US', fmtopts).format(area_in_ha);
  return area_text + ' ' + unit_prefix + 'ha';
}


export function tostr(x, suffix='', decimals=0) {
  let ret = null;
  if (typeof(x) === 'string') {
      ret = x;
  }

  if (typeof(x) === 'number') {
      ret = x.toFixed(decimals);
  }

  if (ret === null) {
      return 'N/A';
  } else {
      return ret + ' ' + (suffix || '');
  }
}
