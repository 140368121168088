'use strict';

import { setup, setup_fixed_view } from './portal';

/*globals URLSearchParams:false */

const url_params = new URLSearchParams(location.search);
const is_fixed_view = url_params.get('fixed_view') === '1';

if (is_fixed_view) {
  setup_fixed_view();
} else {
  setup();
}
