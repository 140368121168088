'use strict';

import m from 'mithril';
import L from 'leaflet';
import $ from 'jquery';

import { tostr, get_readable_area } from './format-utils';

export const render_annotation_popup = ({layer, area, distance, editing, sharing, element}) => {
  const {id, label, rendered_popup_content} = layer.bce;

  const label_row = label ?
    m('.card-header.bg-white.py-1.px-2',
      m('.annotation__label.h6', m.trust(label))) :
    '';
  const content_row = rendered_popup_content ?
    m('.card-body.annotation__content.border-bottom.px-2.py-0',
      m.trust(rendered_popup_content)) :
    '';

  let area_row = [];
  let distance_row = [];
  let coords_row = [];
  let distance_label = 'Distance:';

  if (area > 0) {
    area_row = [
      m('li.annotation__area.list-group-item.border-0.py-1.px-2', [
        m('strong.mr-1', 'Area:'),
        m('span', get_readable_area(area)),
      ])
    ];
    distance_label = 'Perimeter:';
  }

  if (distance > 0) {
    distance_row = [
      m('li.annotation__distance.list-group-item.border-0.py-1.px-2', [
        m('strong.mr-1', distance_label),
        m('span', `${distance.toFixed(2)} m`),
      ])
    ];
  }

  if (layer instanceof L.Marker) {
    const {lat, lng} = layer.getLatLng();
    const classes = '.list-group-item.border-0.py-1.px-2';
    coords_row = [[lat, 'Latitude'], [lng, 'Longitude']].map(
      ([value, name]) => m(`li.annotation__${name.toLowerCase()}${classes}`, [
        m('strong.mr-1', name + ':'),
        m('span', value.toFixed(6)),
      ]),
    );
  }

  const measurement_rows = [
    ...area_row,
    ...distance_row,
    ...coords_row,
  ];

  let measurements_row = '';
  if (measurement_rows.length > 0) {
    measurements_row = m('ul.popup-data-list.list-group.list-group-flush', measurement_rows);
  }

  const climates_row = m(`div#climate-data-${id}.annotation__climate`, [
    m('.d-flex.justify-content-center.p-2', [
      m('i.fas fa-spinner fa-pulse'),
      m('span.ml-1', 'Querying'),
    ]),
  ]);

  let buttons = [];
  if (editing) {
    buttons = ['Edit', 'Delete'];
  }
  if (sharing) {
    buttons = [...buttons, 'Share'];
  }
  buttons = [...buttons, 'Download'];
  const justify = buttons.length === 1 ? 'end' : 'between';
  const btn_class = '.btn.btn-sm.border-0.btn-outline-primary.text-uppercase.text-weight-bold';
  const footer = m(
    `.card-footer.bg-white.d-flex.justify-content-${justify}.py-1.px-2`,
    buttons.map(btn => {
      const classes = `${btn_class}.annotation__${btn.toLowerCase()}`;
      const btn_id = `#bce-annotation-${btn.toLowerCase()}-${id}`;
      return m(`button${classes}${btn_id}`, btn);
    })
  );

  const root = element || $('<div />')[0];
  m.render(root, m('.card.border-0', [
    label_row,
    content_row,
    measurements_row,
    climates_row,
    footer,
  ]));
  return root;
};

export function render_climate_data(climate_data_id, data) {
  // each sublist is [datatype, data description, data unit]
  const disp_datatypes = [
    ['avg_rainfall', 'Avg. Rainfall', '(mm/yr)'],
    ['climate_koppen', 'Climate', null],
  ];

  const items = disp_datatypes.map(([type, desc, unit]) => {
    if (!data[type]) {
      return;
    }

    const value = data[type][0];
    const attrib = data[type][1];
    const icon = attrib ? m('i.far.fa-info-circle', {title: attrib}) : '';

    return m(`li.list-group-item.border-0.py-1.px-2.${type}`, [
      m(`strong.mr-1`, `${desc}:`),
      m(`span.mr-2`, value ? tostr(value, unit) : 'N/A'),
      icon,
    ]);
  });

  const root = $(`#${climate_data_id}`).get(0);
  m.render(root, m('ul.climate-list.list-group.list-group-flush', items));
}
