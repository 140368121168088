'use strict';

/**
 * Opens a browser download dialog for the supplied url using the
 * saveas_filename as the download filename
 */
export function open_download_dialog_for_url(url, saveas_filename=null) {
  // taken from https://stackoverflow.com/a/18197341/8297
  let element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');

  if (typeof saveas_filename === 'string') {
    element.setAttribute('download', saveas_filename);
  } else {
    element.setAttribute('download', 'dendra-download');
  }

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function open_download_dialog_for_data(data, saveas_filename=null) {
  const json_blob = new Blob([JSON.stringify(data)], {type: 'application/json'});
  const url = URL.createObjectURL(json_blob);
  open_download_dialog_for_url(url, saveas_filename);
}
