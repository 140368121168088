'use strict';

export const RASTER_LAYER_TYPE = 'raster-layer';
export const VECTOR_LAYER_TYPE = 'vector-layer';
export const LABELLER_VECTOR_TYPE = 'labeller-vector-layer';
export const RASTERIZED_LABELLER_VECTOR_TYPE = 'rasterized-labeller-vector-layer';
export const FEATURE_GROUP_TYPE = 'skai_feature-group';
export const MAPPING_BOUNDARY = 'mapping-boundary';
export const LAYER_NODE_TYPES = [
  RASTER_LAYER_TYPE,
  VECTOR_LAYER_TYPE,
  LABELLER_VECTOR_TYPE,
  RASTERIZED_LABELLER_VECTOR_TYPE,
  FEATURE_GROUP_TYPE,
  MAPPING_BOUNDARY
];

const is_layer_type = layer_type => type => type === layer_type;

export const is_raster = is_layer_type(RASTER_LAYER_TYPE);
export const is_vector = is_layer_type(VECTOR_LAYER_TYPE);
export const is_labeller_vector = is_layer_type(LABELLER_VECTOR_TYPE);
export const is_rasterized_labeller_vector = is_layer_type(RASTERIZED_LABELLER_VECTOR_TYPE);
export const is_feature_group = is_layer_type(FEATURE_GROUP_TYPE);
export const is_mapping_boundary = is_layer_type(MAPPING_BOUNDARY);
