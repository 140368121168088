'use strict';

import L from 'leaflet';
import { kml as kml_to_geojson } from '@tmcw/togeojson';
import bootbox from 'bootbox';
import $ from 'jquery';

import MapUtils from './labeller/map-utils';
import { get_readable_area } from './format-utils';
import * as types from './types';

// the minimum area measurement that is displayed. Areas below this are ignored
// in most cases. This is b/c we mostly report area in ha and anything less than
// 0.01 ha = 100 sqm isn't something worth reporting in our opinion
export const MIN_AREA_MEASUREMENT_FOR_DISPLAY_SQM = 100;

export async function make_vector_layer(data, leaflet_id) {
  let src_url = data.render_url;
  let style = data.render_options.style || {};
  let layer = null;

  src_url = src_url.replace('{access_token}', data.access_token);

  console.log('VECTOR.STYLE', style);

  let radius = 0.5;
  if ('radius' in style) {
    radius = style.radius;
  }

  let style_layer = L.geoJson(null, {
    pointToLayer: function(_, latlng) {return L.circle(latlng, {radius:radius});},
    style: function() { return style; },
  });

  let layer_properties = {};

  if (data.render_options.file_type.toLowerCase() === 'kml') {
    let resp = await $.get(src_url);
    layer = style_layer.addData(kml_to_geojson(resp));
    console.log('Loaded KML layer', layer);
  } else if (data.render_options.file_type.toLowerCase() === 'geojson') {
    let geojson = await $.get(src_url);
    // we don't need to do coordinate transforms b/c all 'modern'
    // geojson is in the same defined coordinate system. See
    // https://tools.ietf.org/html/rfc7946 section 4. Coordinate Reference
    // System.
    layer = style_layer;

    // if we get a string instead of a dictionary it means content type wasn't set properly. If
    // that is the case we try to parse the string. We can't rely on the content type being correct
    // all the time either b/c we intend to support geojson from places we don't control, e.g.
    // government services etc.
    if (typeof geojson === 'string' || geojson instanceof String) {
      geojson = JSON.parse(geojson)
    }

    layer.addData(geojson);

    // SKAI-943: it seems that if geojson is a FeatureCollection its properties will be missing
    // in the data returned layer.toGeoJSON(). This is unfortunate b/c the top level properties
    // contains things like the description etc. In order to preserve this we extract the
    // properties and store it into .bce_portal later.
    layer_properties = geojson.properties;
  } else {
    console.log('SOURCE TYPE NOT RECOGNISED');
    bootbox.alert('Source type not set on layer. Please contact SKAI support.');
    return null;
  }

  // initialise the layer
  if (layer !== null) {
    layer.bce = data;
    // we set the type b/c sometimes we have to be able to construct the layer URL
    // w/o the node. This is a terrible hack however.
    layer.bce_portal = {
      id: leaflet_id,
      type:'vector',
      access_time_ms: +(new Date()),
      properties: layer_properties,
    };

    let tpcontent = layer.bce.name;

    if (layer.bce.render_options.show_area !== false) {
      // get area in sqm
      let area = MapUtils.get_layer_area(layer);

      if (area >= MIN_AREA_MEASUREMENT_FOR_DISPLAY_SQM) {
        tpcontent += ' (' + get_readable_area(area) + ')';
      }
    }

    if (tpcontent) {
      layer.bindTooltip(tpcontent, {
        sticky: true,
      });
    }

  }

  if (layer !== null) {
    let gjson = layer.toGeoJSON();
    gjson = L.geoJSON(gjson);
    layer.bce.render_options.centre_coordinates = gjson.getBounds().getCenter();
    console.log('COMPUTED CENTRE=', layer.bce.render_options.centre_coordinates);

    console.log('MAKE', layer.bce_portal.id);
  }

  /* XXX
   * I tried showing the layer loading indicator just before returning but it
   * won't show up until the layer is rendered, so unless I delay show_layer
   * I can't show the loading indicator properly. This leads to a poor UX
   * experience with something like the microsites which have over 3000
   * points each and takes ~2 second to render.
   */
  return layer;
}

export function make_raster_layer(data, leaflet_id) {
  let layer_url = data.render_url;
  let layer = L.tileLayer(layer_url, {
      attribution: data.attribution,

      // we always allow zooming up to level 26 so all layers can 'keep up'
      // with UHR layers. Otherwise layers will blink on and off as the user
      // zooms in which is very distracting.
      maxZoom: 26,
      // even though the 'native' zoom of the source may be less
      maxNativeZoom: data.render_options.max_zoom,
      tms: true,
      layer_id: data.id,
      access_token: data.access_token,
      zIndex: data.render_options.zindex,
  });

  layer.bce = data;
  if (data.boundary) {
    layer.bce.boundary = {
      type: 'FeatureCollection',
      features: [{type: 'Feature', geometry: data.boundary}]
    };
  }

  // we set the type b/c sometimes we have to be able to construct the layer URL
  // w/o the node. This is a terrible hack however.
  layer.bce_portal = {
    id: leaflet_id,
    type: 'raster',
    access_time_ms: +(new Date()),
    is_loading_tiles: false
  };

  console.log('MAKE', layer.bce_portal.id);
  return layer;
}

const get_feature_layer = async (feature) => {
  const url = '/portal/layer/' + feature.pk + '/json';
  const resp = await $.get(url);
  const leaflet_id = `${resp.feature_type}.${resp.id}`;

  if (resp.feature_type === types.RASTER_LAYER_TYPE) {
   return make_raster_layer(resp, leaflet_id);
  } else if ([types.VECTOR_LAYER_TYPE, types.LABELLER_VECTOR_TYPE].indexOf(resp.feature_type) !== -1) {
    return await make_vector_layer(resp, leaflet_id);
  }
};

export async function make_grouped_layer(data, leaflet_id) {
  let layers = await Promise.all(data.render_options.skai_features.map(feature => get_feature_layer(feature)));
  layers = layers.filter(l => !!l);
  if (layers.length === 0) {
      return;
  }
  const layer = L.layerGroup(layers);

  layer.bce_portal = {
    id: leaflet_id,
    type: 'skai_feature-group',
    access_time_ms: +(new Date()),
    is_loading_tiles: false
  };

  layer.bce = data;
  if (data.boundary) {
    layer.bce.boundary = {
      type: 'FeatureCollection',
      features: [{type: 'Feature', geometry: data.boundary}]
    };
  } else {
    const centers = layers.map(l => {
      if (l.toGeoJSON) {
        return L.geoJSON(l.toGeoJSON()).getBounds().getCenter();
      } else if (l.bce.render_options.centre_coordinates) {
        return L.latLng(l.bce.render_options.centre_coordinates);
      }
    }).filter(l => !!l);
    if (centers.length > 0) {
      layer.bce.render_options.centre_coordinates = L.latLngBounds(centers).getCenter();
    } else {
      console.error('This group layer has neither boundary nor centre. This layer cannot be drawn.');
      return;
    }
  }

  console.log('MAKE', layer.bce_portal.id);
  return layer;
}
