'use strict';

// Polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import jstree from 'jstree';
import Cookies from 'js-cookie';
import $ from 'jquery';
import DOMPurify from 'dompurify';
import feather from 'feather-icons';
import bootbox from 'bootbox';
import wellknown from 'wellknown';
import leafletPip from '@mapbox/leaflet-pip';
import * as Sentry from '@sentry/browser';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tooltip';
import 'fontawesome';
import 'jquery-peek-a-bar/dist/js/jquery.peekabar';
import 'leaflet-contextmenu';
import 'leaflet-control-geocoder';
import 'leaflet-draw';
import 'leaflet-easybutton';
import './leaflet-measure/leaflet-measure';
import Splide from '@splidejs/splide';

import MapUtils from './labeller/map-utils';
import { render_climate_data, render_annotation_popup } from './popup';
import { get_readable_area } from './format-utils';
import { MIN_AREA_MEASUREMENT_FOR_DISPLAY_SQM, make_raster_layer, make_vector_layer, make_grouped_layer } from './make-layers';
import * as types from './types';
import { open_download_dialog_for_url } from './utils';
import { show_banner } from './components/banner';
import { FetchError } from './components/fetch';

import tokml from './tokml';

/*globals URLSearchParams:false */

/**
 * Extended layer properties
 * =========================
 *
 * Each leaflet layer has the following properties added:
 * bce: contains the json representation of a backend layer, e.g. SKAIFeature
 * bce_portal: contains portal specific extensions such as custom id with
 * which to identify layers.
 */

/* jshint ignore:start */
const platform_settings = JSON.parse(
  document.getElementById("platform-settings")?.textContent ?? "{}"
);

const csrf_cookie = Cookies.get(platform_settings.csrf_cookie_name ?? "csrftoken");
/* jshint ignore:end */
/* global platform_settings,csrf_cookie */

if(platform_settings.sentry) {
  Sentry.init(platform_settings.sentry);
}

$.ajaxSetup({
  "headers": {
    "X-CSRFToken": csrf_cookie,
  },
});

// global variables
var g_vars = {
  map: null,
  project_tree: null,

  // leaflet draw FeatureLayer. Houses annotations.
  drawn_items: null,

  // annotations hidden by the user, keyed by annotation id
  hidden_annotations: {},

  // leaflet_id of a layer that has been shown at least once
  // string:bool
  shown_layers: {},

  // leaflet_id of layers that are visible
  // string:leaflet_layer
  visible_layers: {},

  ordered_skai_features: [],

  // true if during setup the map view has been set. This prevents
  // the map from being set to default view
  setup_did_set_map_view: false,

  annotation: {
    // if true share annotation UI is shown
    sharing: true,

    // if true the annotation label editing UI is shown
    editing: true,
  },

  recentre: {
    // Leaflet Bounds object, if set then map will recentre to the specified
    // bounds
    'bounds': null,

    // Leaflet LatLng object, if set then map will recentre to the latlng
    'latlng': null,

    // int, when latlng is used zoom will be set to this value, defaults to 16
    'zoom': 16,
  },

  fitBoundsMaxZoom: 18,

  // layer loading indicator singleton
  layer_loading_indicator: null,

  species_report_query_radius_m: 50000,

  // tracks which nodes was last shown/hidden so a kb shortcut can be set
  // to toggle that layer on/off to aid in visual inspection/comparison
  last_interaction_node: null,

  // tracks the status of the layer metadata panel shown
  // on the bottom right
  layer_metadata: {
    // the control that is showing layer metadata. There is only one.
    'panel': null,
    // the html shown inside the metadata legend. We keep track of it
    // to avoid unncessary updates
    'html': null,
    'minimised': false,
  },

  // Collection of field photos currently displayed
  field_photos: [],

  // reference to field photo carousel
  field_photo_slider: null,
};

function setup_global_hotkeys() {
  $(document).on('keydown', function (e) {
    const event = e.originalEvent;

    if (event.isComposing || event.keyCode === 229 || is_modal_open()) {
      return;
    }

    switch (event.key) {
      case '.':
        let node = g_vars.last_interaction_node;
        let tree = $('#active_project_tree .project-tree__tree').jstree(true);
        if (node) {
          if (node.state.selected) {
            tree.uncheck_node(node);
          } else {
            tree.check_node(node);
          }
        }
        break;
      case 'H':
        const controls = $('.leaflet-top');

        if (!$('#map').data('can-hide-controls')) {
          break;
        }

        if (!controls.hasClass('d-none')) {
          bootbox.dialog({
            message: '<h3 class="map-controls-hidden__title">Map Controls Hidden</h3><p>Press <kbd>H</kbd> again to show them.</p>',
            className: 'map-controls-hidden',
            size: 'medium',
            buttons: {
              confirm: {
                label: 'Ok',
                className: 'map-controls-hidden__confirm btn-primary',
              },
            },
          });
        }
        controls.toggleClass('d-none');
        break;
    }
  });
}

function is_modal_open() {
  return document.querySelector('.modal.show') !== null;
}

function tree_node_is_of_layer_type(node) {
  return types.LAYER_NODE_TYPES.indexOf(node.data.type) !== -1;
}

function show_peekabar_with_html(html) {
  let bar = new $.peekABar({
    backgroundColor: 'transparent',
    html: html,
    padding: '1rem',
    animation: {
      type: 'slide',
      duration: '250',
    },
  });
  bar.show();

  // this is required when occasionally, and since I can't pinpoint
  // why it it sometimes needed and sometimes not it will be done
  // everytime
  window.FontAwesome.dom.i2svg();

  return bar;
}

function show_layer_loading_indicator() {
  if (g_vars.layer_loading_indicator !== null) {
    return;
  }

  let indicator = show_peekabar_with_html('<i class="fas fa-spinner fa-pulse"></i> Loading...');
  g_vars.layer_loading_indicator = indicator;
}

function hide_layer_loading_indicator() {
  if (g_vars.layer_loading_indicator === null) {
    return;
  }

  g_vars.layer_loading_indicator.hide();
  g_vars.layer_loading_indicator = null;
}

function is_layer_tiles_loading(layer) {
  let is_loading = false;

  if (types.is_raster(layer.bce.feature_type)) {
    if (layer.bce_portal && layer.bce_portal.is_loading_tiles) {
      is_loading = true;
    }
  } else if (types.is_feature_group(layer.bce.feature_type)) {
    layer.eachLayer(sublayer => {
      if (is_layer_tiles_loading(sublayer)) {
        is_loading = true;
      }
    });
  }

  return is_loading;
}

function layers_still_loading() {
  let isloading = false;

  Object.values(g_vars.visible_layers).forEach(layer => {
    if (is_layer_tiles_loading(layer)) {
      isloading = true;
    }
  });

  return isloading;
}

function start_monitoring_layer(layer) {
  // only monitor our layers, i.e. those with .bce_portal property
  if (layer.hasOwnProperty('bce_portal') === false) {
    return;
  }

  function loading_tile() {
    if (layer.bce_portal.is_loading_tiles === false) {
      layer.bce_portal.is_loading_tiles = true;
      console.log('LOADING START', layer.bce.id);

      // show the loading indicator if we are still loading
      // when timeout expires
      setTimeout(function() {
        if (layers_still_loading()) {
          console.log('LOADING STILL', layer.bce.id);
          show_layer_loading_indicator();
        }
      }, 1750);
    }
  }

  function visible_tiles_loaded() {
    if (layer.bce_portal.is_loading_tiles) {
      layer.bce_portal.is_loading_tiles = false;
      // there is a delay to avoid the indicator blinking into
      // existence and blinking away right after
      setTimeout(function () {
        if (layers_still_loading() === false) {
          hide_layer_loading_indicator();
        }
      }, 1000);
      console.log('LOADING DONE', layer.bce.id);
    }
  }

  if (types.is_feature_group(layer.bce.feature_type)) {
    layer.eachLayer(start_monitoring_layer);
  } else if (types.is_raster(layer.bce.feature_type)) {
    layer.on('tileloadstart', loading_tile);
    layer.on('load', visible_tiles_loaded);
  }

  console.log('MONITORING', layer.bce.id, layer.bce.name);
}

/**
 * Shows the given html as a legend
 */
function show_html_in_panel(html) {
  // santised the input first to avoid XSS attacks
  let config = {
    ADD_ATTR:['target'],
  };
  html = DOMPurify.sanitize(html, config);

  let is_minimised = g_vars.layer_metadata.minimised;
  let cur_html = g_vars.layer_metadata.html;

  if (cur_html === html) {
    return;
  } else {
    g_vars.layer_metadata.html = html;
  }

  //console.log('SHOW LEGEND', html);

  let cur_panel = g_vars.layer_metadata.panel;

  let map = g_vars.map;
  let mapevents = [map.dragging, map.doubleClickZoom, map.scrollWheelZoom];
  function disablemapevents() {
    // disable map events when mouse is over control
    mapevents.forEach(ev => {
      ev.disable();
    });
  }

  function enablemapevents() {
      // re-enable on the way out
      mapevents.forEach(ev => {
        ev.enable();
      });
  }

  function set_minmax_onclick() {
    setTimeout(() => {
      let minmaxbtn = document.getElementById('metadata-panel-minmax');

      // somehow we can end up here? Race condition? Anyway if the button
      // doesn't exist then just go away.
      //
      // https://sentry.io/organizations/dendra/issues/1689370171/events/96ecd79958464c078ede752c42042978/
      if (!minmaxbtn) {
        return;
      }

      minmaxbtn.onclick = () => {
        console.log('CLICKED');
        g_vars.layer_metadata.minimised = !g_vars.layer_metadata.minimised;
        // XXX this is a hack to force the next show_html_in_panel to update
        g_vars.layer_metadata.html += 'injustice anywhere is injustice everywhere';
        update_layer_panels();

        if (g_vars.layer_metadata.minimised) {
          // when we minimise it is possible for the control div's
          // onmouseout to not trigger resulting in non-responsive map.
          // Therefore we explicitly enable map interactions on minimise
          enablemapevents();
        }
      }
    },
    // it is probably sufficient to use 0 but just in case we delay
    // 100ms. The user won't notice.
    0.1);
  }

  if (html.length > 0) {
    let btn_class = "fa-window-minimize";
    if (g_vars.layer_metadata.minimised) {
      btn_class = "fa-window-maximize";
    }

    let control_hdr = `<div class="layers-metadata__controls">
                         <span id="metadata-panel-minmax" class="layers-metadata__minmax">
                           <i class="far ${btn_class}"></i>
                         </span>
                       </div>`;
    let div = L.DomUtil.create('div', 'info layers-metadata');

    div.onmouseover = () => {
      disablemapevents();
    };

    div.onmouseout = () => {
      enablemapevents();
    };

    div.innerHTML = control_hdr;
    if (is_minimised === false) {
      div.innerHTML += html;
    }

    if (cur_panel) {
      cur_panel.getContainer().innerHTML = div.innerHTML;
      set_minmax_onclick();
    } else {
      // based on http://leafletjs.com/examples/choropleth/
      let legend = L.control({position:'bottomright'});

      // we need this delay b/c the element won't be in the DOM
      // until the div we have returned has been added.
      legend.onAdd = function(map) {
        set_minmax_onclick();
        return div;
      };
      legend.addTo(g_vars.map)

      g_vars.layer_metadata.panel = legend;
    }
  } else {
    if (cur_panel) {
      cur_panel.remove();
      g_vars.layer_metadata.panel = null;
      // XXX we do not reset this b/c otherwise it means if the user scrolls
      // away from all layers with metadata while the panel is minised then
      // scrolls them back into view the metadata will expand all at once.
      //g_vars.layer_metadata.minimised = false;
    }
  }

}


/**
 * Shows metadata for the layer. Currently this consists of
 * - legend
 * - description
 */
function render_metadata_html_for_layer(layer, dataset_name) {
  if (types.is_feature_group(layer.bce.feature_type) && !layer.bce.render_options.merge_legends) {
    const htmls = [];
    layer.eachLayer(sublayer => {
      const html_part = render_metadata_html_for_layer(sublayer, dataset_name);
      if (html_part) {
        htmls.push(html_part);
      }
    });
    return htmls.join('<br/>');
  }

  let title = layer.bce.name;
  let desc, legend;

  if (dataset_name) {
    title = dataset_name + ' <i class="fas fa-angle-right"></i> ' + title;
  }

  if (layer.bce.description && layer.bce.description.length > 0) {
    desc = layer.bce.description;
  }

  // if desc is still undefined see if we can find it in
  // the layer's geojson
  if (desc === undefined) {
    if (layer.toGeoJSON !== undefined) {
      let layergeojson = layer.toGeoJSON();

      /*
       * SKAI-943
       * Extract description in the following order of preference:
       * 1. the top level feature collection extracted into layer.bce_portal.geojson_properties
       * 2. the description of the first feature
       */
      desc = layer.bce_portal.properties?.description ||  //jshint ignore:line
             layergeojson.features[0]?.properties?.description;  // jshint ignore:line
      if (!!desc) {
        console.log('Extracted description from vector file:', desc);
      }
    }
  }

  let legend_info = layer.bce.legend || {};

  let alllbls = Object.keys(legend_info).sort((a, b) => {
    // XXX This is a hack to implement consistently sorted
    // legend keys for area classification. The true
    // fix is to use sorted 2-tuples for legend instead of
    // dict/object which was a poor design choice.
    if (a.indexOf(':') >=0) {
      a = a.split(':')[1];
    }

    if (b.indexOf(':') >= 0) {
      b = b.split(':')[1];
    }

    console.log('COMP', a,b);
    return a.localeCompare(b);
  });

  if (alllbls.length > 0) {
    legend = '<h5 class="layers-metadata__subtitle">Legend</h5>';

    for (let idx = 0; idx < alllbls.length; ++idx) {
      let lbl = alllbls[idx];
      let colour = legend_info[lbl];
      console.log('LEGEND', lbl, colour);

      let line = '';
      if (colour !== null && colour.length !== 0) {
        line = `<i class="fas fa-square"
                style="background-color: ${colour}; border: thin solid black; color:#00000000"/>
                </i>&nbsp;${lbl}<br/>`;
      } else {
        line = `${lbl}<br/>`;
      }
      legend += line;
    }
  }

  // for vector layers which can be coloured we add a square with the
  // indicated col
  let titleprefix = '';
  if (layer.bce.render_options.style && layer.bce.render_options.style.color) {
    titleprefix = `<i class="fas fa-square"
            style="background-color: ${layer.bce.render_options.style.color};
                   border: thin solid black;
                   color:#00000000;
                   margin-right: 0.2em;"/>
            </i>`;
  }

  let html = `<h1 class="layers-metadata__title">${titleprefix}${title}</h1>`;
  let sections = 0;
  let has_metadata = false;

  function addsection(sechtml, section_classes="", primary=true) {
    const sep = '<hr class="layers-metadata__separator" />';

    if (sections > 0) {
      if (primary) {
        html += sep;
      } else {
        html += '<br/>';
      }
    }

    if (sections === 0 && primary === false) {
      html += sep;
    }

    html += `<div class="layers-metadata__section${section_classes}">${sechtml}</div>`;
    sections += 1;
    has_metadata = true;
  }

  if (desc) {
    addsection(desc, " layers-metadata__description");
  }

  if (legend) {
    addsection(legend, " layers-metadata__legend");
  }

  // add secondary sections
  sections = 0;
  if (layer.bce.render_options.show_area) {
    let area = MapUtils.get_layer_area(layer);
    if (area > MIN_AREA_MEASUREMENT_FOR_DISPLAY_SQM) {
      addsection(`<span class="layers-metadata__label">Area:</span> ${get_readable_area(area)}`, " layers-metadata__area", false);
    }
  }

  if (layer.bce.render_options.show_feature_count) {
    let count;
    if (types.is_rasterized_labeller_vector(layer.bce.feature_type)) {
      count = layer.bce.label_count;
    } else {
      let gjson = layer.toGeoJSON();
      gjson = L.geoJSON(gjson);
      count = gjson.getLayers().length;
    }
    if (count) {
      addsection(`<span class="layers-metadata__label">Count:</span> ${count}`, " layers-metadata__feature-count", false);
    }
  }

  html = `<div class="layers-metadata__layer">${html}</div>`;
  if (has_metadata) {
    return html;
  } else {
    return null;
  }
}

/**
 * Supported options:
 * - set_view: set map view to "center" of the layer or fit to layer "bounds"
 * - dataset_name: name of the dataset
 */
function show_layer(layer, options={}, finished_cb=undefined) {
  options = $.extend({}, options);

  Promise.resolve(layer).then(function (layer) {
    if (!layer) {
      return;
    }

    g_vars.ordered_skai_features = g_vars.ordered_skai_features.filter(id => id !== layer.bce.id);
    g_vars.ordered_skai_features.push(layer.bce.id);

    let map = g_vars.map;
    let shown_layers = g_vars.shown_layers;
    let visible_layers = g_vars.visible_layers;

    // Add layer to map if it not already visible.

    // XXX Note that layer.bce_portal.id is keyed to data type and its
    // primary key in the database if a layer is loaded twice due
    // to async loading the slower of the two layers will be ignored
    if (layer.bce_portal.id in visible_layers === false) {
      console.log('SHOW LAYER=', layer);

      layer.bce_portal.metadata_html = render_metadata_html_for_layer(layer, options.dataset_name);
      layer.addTo(map);

      // we must update visible_layers before calling
      // update_layer_panels b/c the latter depends on the first
      visible_layers[layer.bce_portal.id] = layer;
      update_layer_panels();
      start_monitoring_layer(layer);
    }

    const {initial_zoom, centre_coordinates} = layer.bce.render_options;
    let {set_view} = options;
    let zoom;

    // on first appearance of the layer, map must move to the layer
    // also figure out how to move map to layer
    // whether to center with a given zoom or fit the bounds
    if (layer.bce_portal.id in shown_layers === false) {
      shown_layers[layer.bce_portal.id] = true;
      console.log('FIRST APPEARANCE');
      set_view = initial_zoom && centre_coordinates && centre_coordinates.length ? 'center' : 'bounds';
      zoom = initial_zoom;
    }

    // move map view only when requested specifically. i.e., set_view is set
    if (set_view === 'center') {
      map.setView(centre_coordinates, zoom);
    } else if (set_view === 'bounds') {
      let bounds;
      if (layer.bce.boundary) {
        bounds = L.geoJSON(layer.bce.boundary).getBounds();
      } else if (typeof layer.getBounds === 'function') {
        bounds = layer.getBounds();
      }
      if (bounds) {
        map.fitBounds(bounds, {maxZoom: g_vars.fitBoundsMaxZoom});
      } else {
        console.error('Could not find the layer bounds for ', layer);
      }
    }

    if (options.show_field_photos) {
      get_field_photos(layer, finished_cb);
    }
    else if (finished_cb !== undefined) {
      finished_cb();
    }
  });
}

function hide_layer(layer) {
  layer.remove();
  if (layer.boundary_layer) {
    layer.boundary_layer.remove();
  }

  g_vars.ordered_skai_features = g_vars.ordered_skai_features.filter(id => id !== layer.bce.id);

  // remove field photos
  g_vars.field_photos = g_vars.field_photos.filter(photo => photo.layer !== layer);
  show_field_photos();

  // we must update visible_layers before calling
  // update_layer_panels b/c the latter depends on the first
  delete g_vars.visible_layers[layer.bce_portal.id];
  update_layer_panels();
}

function get_leaflet_id_for_node(node) {
  return node.data.type + '.' + node.data.id;
}

function toggle_boundary(node) {
  let layer = get_visible_layer_for_node(node);
  if (!layer || !layer.bce.boundary) {
    return;
  }
  if (layer.boundary_layer) {
      layer.boundary_layer.remove();
      layer.boundary_layer = null;
  } else {
      layer.boundary_layer = L.geoJSON(layer.bce.boundary);
      layer.boundary_layer.addTo(g_vars.map);
  }
}

function get_visible_layer_for_node(node) {
  let leaflet_id = get_leaflet_id_for_node(node);
  if (leaflet_id in g_vars.visible_layers) {
    return g_vars.visible_layers[leaflet_id];
  } else {
    return null;
  }
}

function download_node(node, resource_pk) {
  if (!tree_node_is_of_layer_type(node)) {
    return;
  }

  console.log('DOWNLOAD', node.data.type, node.data.id);

  // invoke a POST then use the returned URL to start a download
  let url = make_url_with_node('/portal/layer/{layer_id}/download/' + resource_pk, node);

  $.ajax({
      url: url,
      type: 'POST',
      success: function(result) {
        console.log('DOWNLOAD URL', result.url);
        open_download_dialog_for_url(result.url, result.filename);
      },
      error: function(result) {
        console.log('DOWNLOAD ERROR', result);
      },
  });
}

/**
 * Takes a url_template, which is a string containing keys that are then
 * replaced with the given DOM node's data attributes. Supported keys are:
 * - {layer_id}: node.data.id
 *
 * Nodes are expected nodes from the project tree and contain the
 * necessary data-* attributes.
 */
function make_url_with_node(url_template, node) {
  return url_template.replace('{layer_id}', node.data.id);
}

function disable_node(tree, node) {
  const tree_ref = $.jstree.reference(tree);
  tree_ref.disable_node(node);
  const node_el = tree_ref.get_node(node, true);
  node_el.find('.skai-feature__node-icon, .skai-feature__node-loading-icon').toggleClass('d-none');
}

function enable_node(tree, node) {
  const tree_ref = $.jstree.reference(tree);
  tree_ref.enable_node(node);
  const node_el = tree_ref.get_node(node, true);
  node_el.find('.skai-feature__node-icon, .skai-feature__node-loading-icon').toggleClass('d-none');
}

/**
 * options is passed onto show_layer
 */
function show_node(tree, node, options={}) {
  if (!tree_node_is_of_layer_type(node)) {
    return;
  }

  const tree_ref = $.jstree.reference(tree);

  disable_node(tree, node);

  const finished_cb = function() {
    enable_node(tree, node);
  }

  // get the parent name so we can add it to legends
  let parent = tree_ref.get_path(node, false, true).filter(id => id !== '#');
  parent = parent[parent.length - 2];
  parent = tree_ref.get_node(parent);
  options.dataset_name = parent.data.name;

  let layer = get_visible_layer_for_node(node);
  if (layer !== null) {
    show_layer(layer, options, finished_cb);
  } else {
    load_and_render_layer(node.data.id, options, finished_cb);
  }
}

function hide_node(tree, node) {
  if (!tree_node_is_of_layer_type(node)) {
    return;
  }

  let layer = get_visible_layer_for_node(node);
  if (layer === null) {
    return;
  }

  let shouldhide = true;

  if (shouldhide) {
    console.log('HIDE', node.data.type, node.data.id);
    hide_layer(layer);
  } else {
    tree.check_node(node);
  }
}

function annotation_tree_rclick_menu(clicked_node) {
  let menu = {};

  // annoyingly JS tree forces us to access the data attributes
  // differently b/c this was constructed dynamically as opposed
  // to statically
  let node_data = clicked_node.li_attr;

  let ann_id = clicked_node.original.id;
  let ann_is_hidden = ann_id in g_vars.hidden_annotations;


  function set_node_icon(feather_icon_name) {
    let elements = $.parseHTML(clicked_node.text);
    let span = $(elements[0]);
    span.attr('data-feather', feather_icon_name);


    let tree = $('#annotation_tree');
    // it is annoying that both span and text are HTML DOM node but there
    // isn't a consistent way to get their corresponding html representation
    tree.jstree('rename_node', clicked_node, span[0].outerHTML + elements[1].textContent);
    feather.replace();
  }

  let show_annotation_action = {
    "separator_before": false,
    "separator_after": false,
    "_disabled": ann_is_hidden === false,
    "label":'Show Annotation',
    "action": function(data) {
      show_annotation_with_id(ann_id);
      set_node_icon('map-pin');
    },
  };

  let hide_annotation_action = {
    "separator_before": false,
    "separator_after": false,
    "_disabled": ann_is_hidden === true,
    "label":'Hide Annotation',
    "action": function(data) {
      hide_annotation_with_id(ann_id);
      set_node_icon('eye-off');
    },
  };


  menu.show_annotation_action = show_annotation_action;
  menu.hide_annotation_action = hide_annotation_action;

  /*
   * Debug menu items
   */
  if (node_data["data-editable"] === true) {
    let edit_action = {
      "separator_before": true,
      "separator_after": false,
      "label":'Edit',
      "action": function(data) {
        window.open('/admin/portal/annotation/'+ann_id+'/change',
                    '_blank');
      },
    };
    menu.edit_action = edit_action;
  }

  return menu;
}

function project_tree_rclick_menu(clicked_node) {
  let menu = {};
  let node_data = clicked_node.data;

  /*
   * Data type specific options
   */
  if (tree_node_is_of_layer_type(clicked_node)) {
    menu.show_layer_action = {
        separator_before: false,
        separator_after: false,
        _disabled: false,
        _class: "layer-menu__item layer-menu__centre",
        label:'Centre on Layer',
        action: data => {
          show_node(this, clicked_node, {set_view: 'center'});
          // TODO: for vector layers this causes a double load b/c checking a node
          // triggers show_layer again. Fix me!
          this.jstree(true).check_node(clicked_node);
        },
    };

    menu.zoom_to_layer_action = {
        separator_before: false,
        separator_after: false,
        _disabled: false,
        _class: "layer-menu__item layer-menu__zoom",
        label:'Zoom to Layer',
        action: data => {
          show_node(this, clicked_node, {set_view: 'bounds'});
          this.jstree(true).check_node(clicked_node);
        },
    };

    if (node_data.downloads.length === 1) {
      let resource = node_data.downloads[0];
      menu.download_action = {
        separator_before: true,
        _class: "layer-menu__item layer-menu__download",
        label: "Download " + resource.label,
        action: function() {
          download_node(clicked_node, resource.pk);
        }
      };
    } else if (node_data.downloads.length > 1) {
      let submenu = {};
      menu.download_action = {
        separator_before: true,
        _class: "layer-menu__item layer-menu__submenu layer-menu__downloads",
        label: "Download Data",
        submenu: submenu,
      };
      node_data.downloads.forEach(function(resource) {
        submenu['download_'+resource.pk] = {
          _class: "layer-menu__item layer-menu__download",
          label: 'Download ' + resource.label,
          action: function() {
            download_node(clicked_node, resource.pk);
          }
        }
      });
    }
  }

  /*
   * Internal use menu items
   */

  if (node_data.identifier !== undefined) {
    menu.show_identifier_action = {
      separator_before: true,
      _class: "layer-menu__item layer-menu__identifier",
      label: "Show Identifier",
      action: function(ev) {
        bootbox.alert(node_data.type + ' identifier: [' + node_data.id + '] '+ node_data.identifier);
      },
    };
  }

  // Link to Labeller
  // =================
  setup_labeller_menu(menu, node_data);

  if (node_data.has_dashboard) {
    menu.view_dashboard = {
      separator_before: true,
      _class: "layer-menu__item layer-menu__dashboard",
      label: 'View Dashboard',
      action: function () {
        window.open(`/ngapp/dashboard/project/${node_data.id}`, '_blank');
      }
    };
  }

  // Backend admin menu items
  // ========================
  if (node_data.editable) {
    menu.edit_action = {
      separator_before: true,
      _class: "layer-menu__item layer-menu__edit",
      label: "Edit",
      action: function() {
        let type = node_data.type.toLowerCase();
        if (types.LAYER_NODE_TYPES.indexOf(type) !== -1) {
          type = 'skaifeature';
        }
        window.open(`/admin/portal/${type}/${node_data.id}/change/`, '_blank');
      },
    };
  }

  if (node_data.can_create_custom_grid && types.is_raster(node_data.type)) {
    menu.add_ml_grid = {
      _class: 'layer-menu__item layer-menu__add_ml_grid',
      label: 'Add Custom Grid',
      action: function() {
        window.open(`/labeller/add-custom-grid/${node_data.id}/`, '_blank');
      }
    }
  }

  const has_boundary = [
    types.RASTER_LAYER_TYPE,
    types.FEATURE_GROUP_TYPE,
    types.RASTERIZED_LABELLER_VECTOR_TYPE
  ].indexOf(node_data.type) !== -1;

  if (node_data.toggle_boundary && has_boundary) {
    menu.toggle_boundary_action = {
      "separator_before": true,
      "separator_after": false,
      "label":'Toggle Boundary',
      "action": function() {
        toggle_boundary(clicked_node);
      },
    };
  }

  return menu;
}

function setup_labeller_menu(menu, node_data) {
  if (
    !node_data.labeller_enabled ||
    types.is_feature_group(node_data.type) ||
    types.is_rasterized_labeller_vector(node_data.type)
  ) {
    return;
  }

  function labeller_url(mode) {
    let url = `/labeller/layer/${node_data.id}/start`;
    let searchparams = [];

    if (mode) {
      searchparams.push(['mode', mode]);
    }

    if (types.is_vector(node_data.type)) {
      searchparams.push(['is_vector', '1']);
    }

    if (searchparams.length) {
      url += '?' + (new URLSearchParams(searchparams)).toString();
    }
    return url;
  }

  const submenu = {};
  menu.labeller_submenu = {
    separator_before: true,
    _class: "layer-menu__item layer-menu__submenu layer-menu__labeller",
    label: "Labeller",
    submenu: submenu,
  }

  submenu.labeller_action = {
    separator_before: true,
    _class: "layer-menu__item layer-menu__tagging",
    label: 'Start Tagging',
    action: function() {
      window.open(labeller_url(''), '_blank');
    },
  };

  submenu.start_freestyle_action = {
    separator_before: true,
    _class: "layer-menu__item layer-menu__freestyle-tagging",
    label: 'Start Freestyle Tagging',
    action: function() {
      window.open(labeller_url('freestyle'), '_blank');
    },
  };

  if (types.is_raster(node_data.type)) {
    if (node_data.has_ml_recommended_grid) {
      submenu.start_ml_assisted_tagging = {
        separator_before: false,
        _class: "layer-menu__item layer-menu__start_ml_assisted_tagging",
        label: 'Start ML Asssisted Tagging',
        action: function() {
          window.open(labeller_url('ml-assisted-tagging'), '_blank');
        },
      };
    }

    submenu.start_qc_action = {
      separator_before: false,
      _class: "layer-menu__item layer-menu__qc",
      label: 'Start QC',
      action: function() {
        window.open(labeller_url('qc'), '_blank');
      },
    };

    submenu.start_quadrat_action = {
      separator_before: false,
      _class: "layer-menu__item layer-menu__quadrat",
      label: 'Start Quadrat',
      action: function() {
        window.open(labeller_url('quadrat'), '_blank');
      },
    };
  }

  submenu.review_action = {
    separator_before: true,
    _class: "layer-menu__item layer-menu__review",
    label: 'Review Labels',
    action: function () {
      window.open(labeller_url('review'), '_blank');
    }
  };
}

function deselect_annotation_tree_node_with_id(ann_id) {
  let tree = $('#annotation_tree').jstree(true);
  if (tree) {
    tree.deselect_node(ann_id);
  }
}

function select_annotation_tree_node_with_id(ann_id) {
  let tree = $('#annotation_tree').jstree(true);
  if (tree) {
    tree.activate_node(ann_id);
  }
}

function refresh_annotation_tree() {
  let tree = $('#annotation_tree').jstree(true);
  tree.refresh();
}

function setup_annotation_tree() {
  $('#annotation_tree').jstree({
    core: {
      multiple: false,
      data: {
        url:'/portal/annotation/jstree/json',
        headers:{
          "X-CSRFToken": csrf_cookie,
        },
      },
      check_callback: true,
      themes: {
        icons: true,
        name: 'proton',
      },
    },
    contextmenu: {
      select_node: false,
      items: annotation_tree_rclick_menu,
    },
    //conditionalselect: function(node) { return this.is_leaf(node);},
    plugins : [ "contextmenu"],
  });

  let tree = $('#annotation_tree');

  tree.on('select_node.jstree', function(e, data) {
    // find the matching annotation and go to it
    g_vars.drawn_items.eachLayer(function(layer) {
      if (layer.bce.id === data.node.original.id) {
        console.log('SELECT ANNOTATION', layer.bce);
        let showpopup = true;
        if (typeof layer.getBounds === 'function') {
          g_vars.map.fitBounds(layer.getBounds(), {maxZoom: g_vars.fitBoundsMaxZoom});
        } else if (typeof layer.getLatLng === 'function') {
          g_vars.map.setView(layer.getLatLng());
        } else {
          showpopup = false;
          console.log('Could not get layer location');
        }

        if (layer.isPopupOpen()) {
          showpopup = false;
        }

        if (showpopup) {
          setTimeout(function() {
            layer.openPopup();
          }, 500);
        }
      }
    });
  });

  tree.on('ready.jstree', function(e, data) {
    feather.replace();
  });
}

function setup_project_tree(tree_el, collapsed) {
  show_tree_item_ids();
  apply_package_tag_styles();
  let tree_panel = $(tree_el);
  let tree = tree_panel.find('.project-tree__tree');

  tree.jstree({
    core: {
      check_callback: true,
      // with check boxes this must be true otherwise collapsing/opening
      // projects will select themfalse
      dblclick_toggle: true,
      themes: {
        icons: false,
        name: 'proton',
      },
    },
    checkbox: {
      cascade: '',
      three_state: false,
      keep_selected_style: false,
    },
    contextmenu: {
      select_node: false,
      items: project_tree_rclick_menu.bind(tree),
    },
    //conditionalselect: function(node) { return this.is_leaf(node);},
    plugins : [ "checkbox" , "conditionalselect", "contextmenu"],
  });


  tree.on('select_node.jstree', function (e, data) {
    if (!tree_node_is_of_layer_type(data.node)) {
      data.instance.toggle_node(data.node);
      data.instance.deselect_node(data.node);
    } else {
      show_node(tree, data.node);
      g_vars.last_interaction_node = data.node;
    }
  });

  tree.on('deselect_node.jstree', function (e, data) {
    hide_node(tree, data.node);
    g_vars.last_interaction_node = data.node;
  });

  // when new nodes are exposed we need to re-run feather so
  // icons show up
  tree.on('open_node.jstree', function(e, data) {
    feather.replace();
  });

  tree.on('ready.jstree', function(e, data) {
    // if the tree defaults to closed with low number of nodes it means
    // the user needs to expand everything manually even though it is
    // unncessary. Conversely with a larger number of nodes it is better
    // to have everything closed so the user can find what they want
    // faster
    //
    // 24 chosen arbitarily
    let openall = false;

    // if the total number of nodes is less than 24 open everything
    if (tree.jstree().get_json('#', {'flat': true}).length < 24) {
      openall = true;
    }
    // if the user only has one project then open everything always
    else if (tree.jstree().get_json('#').length === 1) {
      openall = true;
    }

    if (openall && !collapsed) {
      tree.jstree('open_all');
    } else {
      tree.jstree('close_all');
    }

    const selected = tree.find('[data-selected=true]');
    if (selected.length === 1) {

      // Make sure the tree's panel is not collapsed
      show_tree(tree);

      if (!openall || collapsed) {
        tree.jstree('close_all');
        tree.jstree('open_node', selected.attr('id'));
      }

      selected[0].scrollIntoView();

      $.getJSON(`/api/project/${selected.data('id')}/boundary/`, (data) => {
        if (!data.boundary) {
          return;
        }

        const layer = L.geoJSON(data.boundary);
        g_vars.map.fitBounds(layer.getBounds());
      });

      return;
    }
  });

  // -------------------------------------
  // Setup expand and collapse all actions
  // -------------------------------------

  tree_panel.find('.project-tree__collapse-all').click(function(e) {
    tree_panel.find('.project-tree__expand-all').removeClass('d-none');
    $(this).addClass('d-none');
    tree.jstree('close_all');
  });

  tree_panel.find('.project-tree__expand-all').click(function(e) {
    tree_panel.find('.project-tree__collapse-all').removeClass('d-none');
    $(this).addClass('d-none');
    tree.jstree('open_all');
  });
}

function populate_climate_data(climate_data_id, wkt) {
  // start an ajax request to get data for this annotation and then
  // insert it into the popup content
  $.ajax({
    url: '/data/wkt/' + encodeURIComponent(wkt),
    type: 'GET',
    success: function(result) {
      render_climate_data(climate_data_id, result);
    },
    error: function(result) {
      console.log('DATA ERROR:', result);
      render_climate_data(climate_data_id, {});
    },
  });
}

function generate_user_content_for_annotation(layer) {
  // ---------------------------
  // Calculate area and distance
  // ---------------------------
  let distance = 0;
  let area = 0;

  if (layer instanceof L.Polygon) {
    area = MapUtils.get_layer_area(layer);
  }

  if (layer instanceof L.Polyline) {
    let latlngs = layer._defaultShape ? layer._defaultShape() : layer.getLatLngs();
    if (latlngs.length >= 2) {
      for (let i = 0; i < latlngs.length-1; i++) {
        distance += latlngs[i].distanceTo(latlngs[i+1]);
      }
    }
  }

  // --------------------------
  // Create the tooltip content
  // --------------------------
  let tooltip_content = layer.bce.label;

  if (typeof layer.getLayers === 'function') {
    tooltip_content += ' (' + layer.getLayers().length.toString() + ')';
  } else {
    if (area > 0) {
      tooltip_content += ' (' + get_readable_area(area) + ')';
    }
  }

  layer.bindTooltip(tooltip_content, {
    permanent: false,
    sticky: true,
    offset: [0, 0],
    interactive: false,
  });

  // this function will be called on popupopen to get climate data
  function get_climate_data() {
    let wkt = wellknown.stringify(layer.bce.geoJSON);
    let climate_data_id = 'climate-data-' + layer.bce.id;
    populate_climate_data(climate_data_id, wkt);
  }

  // -----------------------
  // Prepare some button ids
  // -----------------------
  let share_btn_id = 'bce-annotation-share-' + layer.bce.id;
  let download_btn_id = 'bce-annotation-download-' + layer.bce.id;
  let edit_btn_id = 'bce-annotation-edit-' + layer.bce.id;
  let delete_btn_id = 'bce-annotation-delete-' + layer.bce.id;
  let planting_plan_btn_id = 'bce-annotation-planting-plan-' + layer.bce.id;

  // ---------------------
  // unbind existing popup
  // ---------------------

  let popup = layer.getPopup();
  if (popup !== null) {
    layer.closePopup();
    layer.unbindPopup();
  }
  popup = L.popup({maxWidth:600, minWidth:350});

  const popup_root = render_annotation_popup({layer, area, distance, ...g_vars.annotation});

  popup.setContent(popup_root.innerHTML);
  layer.bindPopup(popup);

  function set_onclick() {
    $('#'+edit_btn_id).off('click');
    $('#'+edit_btn_id).on('click', function(ev) {
      if (g_vars.annotation.editing === false) {
        return;
      }
      layer.closePopup();
      edit_annotation(layer, function(label, content) {
        if (label !== null) {
          console.log('EDIT ' + layer.bce.id);
          // these 2 lines are necessary so writeLayerToDB has access to new
          // content
          layer.bce.label = label;
          layer.bce.popup_content = content;

          // writeLayerToDB will update the annotation layer so we don't need to
          // do it here ourselves
          writeLayerToDB(layer);
        }
      });
    });

    $('#'+download_btn_id).off('click');
    $('#'+download_btn_id).on('click', function(ev) {
      console.log('DOWNLOAD', layer.bce.id);
      let desc = 'Exported from My Dendra Portal';
      if (layer.bce.rendered_popup_content.length) {
          desc = layer.bce.rendered_popup_content;
      }

      let geoJSON = layer.bce.geoJSON;

      // We assume there is only one feature which should be true since we
      // generated it
      geoJSON.properties.name = layer.bce.label;
      geoJSON.properties.description = '<h1>' + layer.bce.label + '</h1>' + layer.bce.rendered_popup_content;

      let kml = tokml(geoJSON, {
                     documentName: layer.bce.label,
                     documentDescription: 'Exported from My Dendra Portal',
                     name: 'name',
                     description: 'description',
      });

      open_download_dialog_for_url('data:application/vnd.google-earth.kml+xml,' + encodeURIComponent(kml),
                                   layer.bce.label + '.kml');

    });

    $('#'+share_btn_id).off('click');
    $('#'+share_btn_id).on('click', function(ev) {
      console.log('SHARE', layer.bce.id);
      let payload = {enable:true}
      $.ajax({
        url: '/portal/annotation/'+layer.bce.id+'/share',
        type: 'POST',
        data: JSON.stringify(payload),
        contentType: 'application/json;charset=UTF-8',
        success: function(result) {
          let msg = 'Your annotation can be shared with others via the following URL:<br/>'+
                    '<a href="{url}" target="_">{url}&nbsp;<span class="fal fa-external-link-square"></span></a>';
          msg = msg.replace(/{url}/g, result.view_url);
          bootbox.alert({
            title:'Annotation Sharing URL',
            message:msg,
          });
        },
        error: function(result) {
          bootbox.alert('Failed to save annotation')
        },
      });
    });

    $('#'+delete_btn_id).off('click');
    $('#'+delete_btn_id).on('click', function(ev) {
      delete_annotation_with_id(layer.bce.id, {confirm:true});
    });

    $('#'+planting_plan_btn_id).off('click');
    $('#'+planting_plan_btn_id).on('click', function(ev) {
      window.open('/portal/pp/'+area.toFixed(2));
    });
  }

  layer.on('popupopen', function(ev) {
    console.log('POPUPOPEN');
    popup.setContent(popup_root.innerHTML);
    set_onclick();

    setTimeout(get_climate_data, 500);
    select_annotation_tree_node_with_id(layer.bce.id);
  });
}

function show_annotation_with_id(ann_id, options={}) {
  let drawn_items = g_vars.drawn_items;
  let hidden_annotations = g_vars.hidden_annotations;

  if (ann_id in hidden_annotations) {
    drawn_items.addLayer(hidden_annotations[ann_id]);
    delete hidden_annotations[ann_id];
  }
}

function hide_annotation_with_id(ann_id, options={}) {
  let drawn_items = g_vars.drawn_items;
  let hidden_annotations = g_vars.hidden_annotations;

  drawn_items.eachLayer(function(layer) {
    if (layer.bce.id === ann_id) {
      hidden_annotations[layer.bce.id] = layer;
      drawn_items.removeLayer(layer);
    }
  });

  deselect_annotation_tree_node_with_id(ann_id);
}
/**
 * options:
 * - confirm: if true then the user will be prompted to confirm the operation.
 *            Default: undefined.
 */
function delete_annotation_with_id(ann_id, options={}) {
  function do_delete() {
    $.ajax({
      url: '/portal/annotation',
      data: JSON.stringify(ann_id),
      type: 'DELETE',
      success: function(result) {
        console.log('DELETE ' + ann_id);
        refresh_annotation_tree();

        // search drawn_items, if it exists remove it
        g_vars.drawn_items.eachLayer(function(layer) {
          if (layer.bce.id === ann_id) {
            g_vars.drawn_items.removeLayer(layer);
          }
        });
      }
    });
  }

  if (options.confirm === true) {
    bootbox.confirm({
      message: '<h3 class="annotation-delete__title">Delete Annotation?</h3>',
      className: 'annotation-delete',
      size: 'small',
      buttons: {
        confirm: {
          label: '<i class="fas fa-fw fa-sm fa-trash-alt"></i> Delete',
          className: 'annotation-delete__confirm btn-danger',
        },
        cancel: {
          label: '<i class="fas fa-fw fa-sm fa-times"></i> Cancel',
          clasName: 'annotation-delete__cancel btn-light',
        }
      },
      callback: function(should_delete) {
        if (should_delete) {
          do_delete();
        }
      }
    });
  } else {
    do_delete();
  }

}

/**
 * On cancel callback is invoked with null for label and content
 */
function edit_annotation(layer, cb, creating = false) {
  let form = [
    '<div class="form-group">',
      '<label for="annotation-label-input">Label</label>',
      '<input type="text" class="form-control" id="annotation-label-input" value="{label}">',
    '</div>',
    '<div class="form-group">',
      '<label for="annotation-content-input">Content</label>',
      '<textarea rows=10 class="form-control" id="annotation-content-input">',
        '{content}',
      '</textarea>',
      '<div class="text-right text-muted">',
        '<a target="_" href="https://daringfireball.net/projects/markdown/syntax">Markdown<i class="fal fa-external-link-square"></i></a> with ',
        '<a target="_" href="https://help.github.com/articles/organizing-information-with-tables/">tables<i class="fal fa-external-link-square"></i></a> supported.',
      '</div>',
    '</div>',
  ].join('');

  if (layer.hasOwnProperty('bce') === false) {
    layer.bce = {
      'label':'',
      'popup_content':'',
    };
  }

  form = form.replace('{label}', layer.bce.label);
  form = form.replace('{content}', layer.bce.popup_content);

  let dialog = bootbox.dialog({
    title: creating ? 'Add Annotation' : 'Edit Annotation',
    message: form,
    size: 'large',
    buttons: {
      cancel: {
        label: 'Cancel',
        className: 'annotation-form__cancel btn btn-secondary btn-sm',
        callback: function() {
          cb(null, null);
        }
      },
      save: {
        label: creating ? 'Add' : 'Save',
        className: 'annotation-form__save btn btn-primary btn-sm',
        callback: function() {
          let lbl = $('#annotation-label-input').val();
          let content = $('#annotation-content-input').val();
          cb(lbl, content);
          setTimeout(() => dialog.modal('hide'), 100);
        }
      }
    }
  });

  dialog.on('shown.bs.modal', function () {
    $('#annotation-label-input').focus();
  });
}

/**
 * annotation is the JSON object returned by the server
 */
function load_annotation(annotation, drawn_items, openPopup=false) {
  //console.log('LOAD_ANN', annotation.id);
  let geojson = annotation.geoJSON;
  let label = annotation.label;

  //console.log('Restoring ' + geojson);
  //console.log(label);
  //console.log(geojson);

  // XXX this looks like it is wrong but it isn't! leaflet.draw
  // must deal with 'primitive' layers no layergroups etc, which
  // is what L.geoJSON is, it extends FeatureGroup.
  let jsongroup = L.geoJSON(geojson, {
    style: function(feature) {
      return annotation.style;
    },
  });

  if (jsongroup.getLayers().length === 1) {
    jsongroup.eachLayer(function(layer) {
      layer.bce = annotation;
      generate_user_content_for_annotation(layer);
      drawn_items.addLayer(layer);

      if (openPopup) {
        layer.openPopup();
      }
    });
  } else {
    console.log('COMPLEX ANN');
    jsongroup = L.geoJSON(geojson, {
      style: function(feature) {
        return annotation.style;
      },
      pointToLayer: function(pt, latlng) {
        return L.circle(latlng, {radius:0.15});
      }
    });

    jsongroup.bce = annotation;
    generate_user_content_for_annotation(jsongroup);
    drawn_items.addLayer(jsongroup);

    if (openPopup) {
      jsongroup.openPopup();
    }
  }
}

function load_shared_annotation(map, drawn_items) {
  let path = window.location.pathname;
  let share_key = path.substr(path.lastIndexOf('/')+1);
  let url = '/portal/annotation/shared/{key}/json'.replace('{key}', share_key);

  console.log('LOAD_SHARED',share_key);
  $.ajax({
    url: url,
    type: 'GET',
    success: function(result) {
      load_annotation(result, drawn_items);
      let stop = false;
      drawn_items.eachLayer(function(layer) {
        if (stop) {
          return;
        }

        if (typeof layer.getBounds === 'function') {
          stop = true;

          let bounds = layer._bounds;

          g_vars.setup_did_set_map_view = true;
          g_vars.recentre.bounds = bounds;

          setTimeout(function() {
            console.log('ANNOTATION_BOUNDS', bounds);

            map.fitBounds(bounds);
            setTimeout(function() {
              layer.openPopup();
            }, 500);
          }, 500);
        } else if (typeof layer.getLatLng === 'function') {
          stop = true;
          let latlng = layer.getLatLng();

          g_vars.setup_did_set_map_view = true;
          g_vars.recentre.latlng = latlng;

          setTimeout(function() {
            console.log('ANNOTATION_LATLNG', latlng);

            map.setView(latlng, 16);
            setTimeout(function() {
              layer.openPopup();
            }, 500);
          }, 500);
        }
      });
    },
    error: function(result) {
      bootbox.alert({
        title:'Failed to Load Annotation',
        message:result.responseText,
      });
    }
  });

}

function restore_annotations(drawn_items) {
  // restore previously saved features
  $.ajax({
    url: '/portal/annotation/json',
    type: 'GET',
    success: function(result) {
      for (let idx = 0; idx < result.length; ++idx) {
        load_annotation(result[idx], drawn_items);
      }
    }
  });
}

/**
 * options:
 * - no_load: normally an annotation is loaded immediately after its creation.
 *   If no_load is true then this is not done
 *
 * - no_refresh: normally the annotation list is refreshed immediately after
 *   creating an annotation. If no_refresh is true this is not done.
 */
function create_annotation_with_layer(layer, label, content, options={}) {
  if (label === null) {
    return;
  }

  let payload = {
    geojson:layer.toGeoJSON(),
    label:label,
    popup_content:content,
  };

  console.log('PAYLOAD', payload);

  $.ajax({
    url: '/portal/annotation',
    type: 'POST',
    data: JSON.stringify(payload),
    contentType: 'application/json;charset=UTF-8',
    success: function (result) {
      if (options.no_load !== true) {
        load_annotation(result, g_vars.drawn_items, true);
      }

      if (options.no_refresh !== true) {
        refresh_annotation_tree();
      }

      layer.bce = result;
    },
    error: function (result) {
      bootbox.alert({
        title:'Failed to Load Annotation',
        message:result.responseText,
      });
    },
  });
}

/**
 * options:
 * - no_refresh: normally the annotation list is refreshed immediately after
 *   creating an annotation. If no_refresh is true this is not done.
 */
function writeLayerToDB(layer, options={}) {
  let payload = {
    geojson:layer.toGeoJSON(),
    label:layer.bce.label,
    popup_content:layer.bce.popup_content,
  };

  $.ajax({
    url: '/portal/annotation/' + layer.bce.id,
    type: 'PUT',
    data: JSON.stringify(payload),
    contentType: 'application/json;charset=UTF-8',
    success: function (result) {
      console.log('PUT ' + layer.bce.id);
      $.extend(layer.bce, result);
      generate_user_content_for_annotation(layer);
      if (options.no_refresh !== true) {
        refresh_annotation_tree();
      }
    }
  });
}

function add_leaflet_draw(map, drawn_items) {
  // FeatureGroup is to store editable layers
  drawn_items.addTo(map);

  L.drawLocal.edit.toolbar.buttons.edit = "Edit annotations";
  L.drawLocal.edit.toolbar.buttons.editDisabled = "No annotations to edit";

  L.drawLocal.edit.handlers.edit.tooltip.text = "Drag handles or markers to edit annotations";

  let drawControl = new L.Control.Draw({
    draw: {
      // goGeoJSON doesn't give me enough data to save circles with
      circle: false,
      circlemarker:false,
      rectangle: false,
      polygon: {
        allowIntersection: true,
      }
    },
    edit: {
      featureGroup: drawn_items,
      remove: false,
    }
  });

  drawControl.addTo(map);
  g_vars.drawControl = drawControl;

  // ---------------------------------------------------------
  // Configure handlers to adding/editing/removing annotations
  // ---------------------------------------------------------
  map.on(L.Draw.Event.DRAWSTART, () => {
    const measure_tool = document.querySelectorAll('.leaflet-control-measure');
    measure_tool.forEach(tool => tool.classList.add('disabled'));
  });

  map.on(L.Draw.Event.DRAWSTOP, () => {
    const measure_tool = document.querySelectorAll('.leaflet-control-measure');
    measure_tool.forEach(tool => tool.classList.remove('disabled'));
  });

  map.on(L.Draw.Event.EDITED, function(event) {
    let layers = event.layers;
    layers.eachLayer(writeLayerToDB);
    layers.eachLayer(function(layer) {
      layer.unbindTooltip();
      generate_user_content_for_annotation(layer);
    });
  });

  map.on(L.Draw.Event.DELETED, function(event) {
    let layers = event.layers;
    layers.eachLayer(function(layer) {
      delete_annotation_with_id(layer.bce.id);
    });
  });

  map.on(L.Draw.Event.CREATED, function(event) {
    let layer = event.layer;
    if (layer instanceof L.Polygon) {
      // we have to add the layer to map before
      // we can call intersects() otherwise an JS error
      // occurs
      layer.addTo(map);
    }

    layer.bce = {};
    layer.bce_portal = {};

    setTimeout(function() {

      // set some defaults for edit_annotation
      layer.bce.label = '';
      layer.bce.popup_content = '';

      edit_annotation(layer, function(label, content) {
        create_annotation_with_layer(layer, label, content);
        // remove the created layer as the new layer is loaded from backend and
        // drawn independently
        layer.remove();
      }, true);
    }, 100);
  });

  return drawn_items;
}

function setup_toggle_left_navigation_control(map) {
  function left_nav_toggle() {
    $('#left-navigation').toggleClass('col-sm-3 sidebar-collapsed');
    map.invalidateSize();
  }

  let btn = L.easyButton({
    states:[{
      stateName: 'left-nav-visible',
      icon: 'far fa-expand-alt fa-lg',
      title: 'Expand',
      onClick: function (btn) {
        btn.state('left-nav-hidden');
        left_nav_toggle();
      }
    },
    {
      stateName: 'left-nav-hidden',
      icon: 'far fa-compress-alt fa-lg',
      title: 'Contract',
      onClick: function(btn) {
        btn.state('left-nav-visible');
        left_nav_toggle();
      }
    }]
  });

  btn.state('left-nav-visible')
  return btn;
}
/**
 * In order for this to work g_vars.centre_bounds
 */
function setup_recentre_control() {
  let btn = L.easyButton('fa-crosshairs', function(_, map) {
    let recentre = g_vars.recentre;

    if (recentre.bounds !== null) {
      map.fitBounds(recentre.bounds, {maxZoom: g_vars.fitBoundsMaxZoom});
    } else if (recentre.latlng !== null) {
      map.setView(recentre.latlng, recentre.zoom);
    }
  });

  return btn
}


function setup_geocoder_control(map) {
  let geocoder = L.Control.geocoder({
    expand: 'click',
    defaultMarkGeocode: false,
    geocoder: L.Control.Geocoder.here({
      // necessary to override this otherwise it won't work when portal is
      // served over https
      geocodeUrl: 'https://geocoder.cit.api.here.com/6.2/geocode.json',
      app_id: 'WfwVo23IRvjkQ8Flmbjp',
      app_code: 'mdQ0LbKCU1NhVgBeExkYhw',
    }),
  });

  geocoder.on('markgeocode', function(e) {
    let bbox = e.geocode.bbox;
    map.fitBounds(bbox);
  });

  function parse_latlng(x) {
    x = x.trim();
    let parts = x.split(/[\s,]/);

    let lat = null;
    let lng = null;
    let zoom = 16;

    for (let idx = 0; idx < parts.length; ++idx) {
      let p = parts[idx];
      if (p.length < 1) {
        continue;
      }

      console.log('P:', p);

      // format: +/-ddd.dddd, useful when coordinates are copied out of google
      if (/^[-+]?\d+\.*\d*$/.test(p)) {
        if (lat === null) {
          lat = parseFloat(p);
        } else if (lng === null) {
          lng = parseFloat(p);
        }
      }

      // format: dddd `d` mmmmm `'` ssss.ssss `"` N/S/W/E, useful when
      // coordinates are copied out of gdalinfo
      if (/^\d+d\d+'\d+\.?\d*"[NSEW]$/.test(p)) {
        let pp = p.split(/[d'"]/);
        let deg = parseFloat(pp[0]);
        let min = parseFloat(pp[1]);
        let sec = parseFloat(pp[2]);
        let dir = pp[3];

        let decimal_deg = deg + min / 60 + sec / 3600;
        if (dir === 'S' || dir === 'W') {
          decimal_deg *= -1;
        }

        console.log('DECIMALDEGREE:', decimal_deg);

        if (dir === 'S' || dir === 'N') {
          // S and N are only valid for latitude
          lat = decimal_deg;
        } else {
          lng = decimal_deg;
        }
      }

      // format: dd, indicates zoom, only consumed when lat and lng are both
      // filled
      if (lat !== null && lng !== null) {
        if (/\d+/.test(p)) {
          zoom = parseInt(p);
        }
      }
    }

    console.log('LATLNG:', lat, lng, 'ZOOM', zoom);

    if (lat !== null && lng !== null) {
      return [L.latLng(lat, lng), zoom];
    } else {
      return null;
    }
  }

  // intercept the geocoding attempt and try to parse out lat/lng
  let oldfunc = geocoder._geocode;
  geocoder._geocode = function(suggest) {
    let latlng_zoom = parse_latlng(geocoder._input.value);
    if (latlng_zoom !== null) {
      let latlng = latlng_zoom[0];
      let zoom = latlng_zoom[1];
      map.setView(latlng, zoom);
    } else {
      oldfunc.call(geocoder, suggest);
    }
  };

  // the default behaviour is to toggle the input on click which makes editing
  // or manipulating the search text impossible since clicking on an expanded
  // searchbox immediately collapses it again. We modify toggle to expand only
  // to remove this behaviour since by simply clicking outside of the search box
  // or pressing escape the search box can be dismissed
  geocoder._toggle = function() {
    if (L.DomUtil.hasClass(this._container, 'leaflet-control-geocoder-expanded')) {
      //this._collapse();
    } else {
      this._expand();
    }
  };

  return geocoder;
}

function add_annotation_controls(map, drawn_items) {
  let labelbtn = L.easyButton({
    states: [{
      stateName: 'hide-labels',
      icon: 'far fa-tag',
      title: 'Show annotation labels',
      onClick: function(btn, map) {
        btn.state('show-labels');
        drawn_items.eachLayer(function(layer) {
          let tp = layer.getTooltip();
          layer.unbindTooltip();
          tp.options.permanent = true;
          layer.bindTooltip(tp);
        });
      }
    },
    {
      stateName: 'show-labels',
      icon: 'fas fa-tag',
      title: 'Hide annotation labels',
      onClick: function(btn, map) {
        btn.state('hide-labels');
        drawn_items.eachLayer(function(layer) {
          let tp = layer.getTooltip();
          layer.unbindTooltip();
          tp.options.permanent = false;
          layer.bindTooltip(tp);
        });
      }
    }]
  });

  let annotationbtn = L.easyButton({
    states: [{
      stateName: 'hide-annotations',
      icon: 'far fa-eye-slash',
      title: 'Show annotations',
      onClick: function(btn, map) {
        btn.state('show-annotations');
        map.addLayer(drawn_items);
      },
    },
    {
      stateName: 'show-annotations',
      icon: 'far fa-eye',
      title: 'Hide annotations',
      onClick: function(btn, map) {
        btn.state('hide-annotations');
        map.removeLayer(drawn_items);
      }
    }]
  });

  annotationbtn.state('show-annotations');

  L.easyBar([labelbtn, annotationbtn]).setPosition('topleft').addTo(map);
}

function setup_map_context_menu(map) {
  // --------------------------
  // Setup the map context menu
  // --------------------------
  map.contextmenu.addItem({
    text:'Show coordinates',
    callback: function (ev) {
      const {lat, lng} = ev.latlng;
      const htmlcontent = `
        <div class="card border-0">
          <div class="card-header bg-white py-1 px-2">
            <div class="h6">Coordinates</div>
          </div>
          <ul class="list-group list-group-flush">
            ${[[lat, 'Latitude'], [lng, 'Longitude']].map(([value, name]) => `
              <li class="list-group-item border-0 py-1 px-2">
                <strong class="mr-1">${name}</strong>
                <span>${value.toFixed(6)}</span>
              </li>
            `).join('')}
          </ul>
        </div>`;
      let popup = L.popup();
      popup.setLatLng(ev.latlng);
      popup.setContent(htmlcontent);
      popup.openOn(map);
    },
  });

  map.contextmenu.addItem({
    text: 'Show environment data',
    callback: function (ev) {
      const cid = Math.random().toString(36).substring(3);
      const htmlcontent = `
        <div class="card border-0">
          <div class="card-header bg-white py-1 px-2">
            <div class="h6">Environment Data</div>
          </div>
          <div id="${cid}" class="climate_data">
            <div class="d-flex justify-content-center p-2">
              <i class="fas fa-spinner fa-pulse mr-1"></i>
              <span>Querying</span>
            </div>
          </div>
        </div>`;

      let wkt = 'POINT ({lng} {lat})';
      let p = ev.latlng.wrap();
      wkt = wkt.replace('{lng}', p.lng);
      wkt = wkt.replace('{lat}', p.lat);

      let popup = L.popup({minWidth:200});
      popup.setLatLng(ev.latlng);
      popup.setContent(htmlcontent);
      popup.openOn(map);

      setTimeout(function() {
        populate_climate_data(cid, wkt);
      }, 500);
    },
  });

  map.contextmenu.addItem({
    text: 'Show species',
    callback: function (ev) {
      let wkt = 'POINT ({lng} {lat})';
      let p = ev.latlng.wrap();
      wkt = wkt.replace('{lng}', p.lng);
      wkt = wkt.replace('{lat}', p.lat);

      window.open('/portal/sps/within/' + g_vars.species_report_query_radius_m + '/' + encodeURIComponent(wkt));
    },
  });

  map.contextmenu.addItem({
    text:'Get link to here',
    callback: get_link_here(map, false),
  });

  map.contextmenu.addItem({
    text:'Get link to here (fixed view)',
    callback: get_link_here(map, true),
  });
}

function get_link_here(map, fixed_view) {
  const make_url = event => {
    const location = window.location;
    let url = `${location.protocol}//${location.host}/portal`;
    const params = {
      lat: event.latlng.lat.toFixed(6),
      lng: event.latlng.lng.toFixed(6),
      z:   map.getZoom(),
      fixed_view: +fixed_view,
    };
    // jshint ignore:start
    if(!platform_settings.demo_mode && g_vars.current_basemap?.layer) {
      params.basemap = g_vars.current_basemap.layer.options.id;
      params.show_labels = +g_vars.basemap_labels_shown;
    }
    url += '?';
    url += $.param(params);
    // don't encode the commas
    if (g_vars.ordered_skai_features?.length) {
      url += '&skai_features=' + g_vars.ordered_skai_features.join(',');
    }
    // jshint ignore:end
    return url;
  };

  const make_content = url => {
    return `
      <div class="card border-0">
        <div class="card-header bg-white py-1 px-2">
          <div class="h6">Location Link</div>
        </div>
        <div class="card-body py-1 px-2">
          <a target="_" href="${url}">
            ${url} &nbsp;
            <span class="far fa-external-link"></span>
          </a>
        </div>
      </div>`;
  };

  return function(event) {
    const url = make_url(event);
    const htmlcontent = make_content(url);
    let popup = L.popup();
    popup.setLatLng(event.latlng);
    popup.setContent(htmlcontent);
    popup.openOn(map);
  };
}

/**
 * Determines if the given layer is on screen, i.e. within the mapbounds. A
 * layer can be visible and off-screen when the user scrolls away from the
 * layer.
 */
function layer_is_on_screen(layer) {
  if (types.is_feature_group(layer.bce.feature_type)) {
    let is_on_screen = false;
    layer.eachLayer(sublayer => {
      if (layer_is_on_screen(sublayer)) {
        is_on_screen = true;
      }
    });
    return is_on_screen;
  }

  let mapbounds;
  try {
    mapbounds = g_vars.map.getBounds();
  } catch (e) {
    console.error('Bound is not set yet');
    return;
  }
  //console.log('MAP BOUNDS', mapbounds);
  let gjson;

  if (layer.bce.boundary) {
    gjson = layer.bce.boundary;
  } else if (layer.toGeoJSON) {
    gjson = layer.toGeoJSON();
  }

  if (!gjson || !gjson.features) {
    // when we can't determine visibility we err on the side of caution
    // and say the layer is visible
    return true;
  }

  function check_coords(coords) {
    // this function will descend a nested array structure until it finds an
    // array containing arrays-of-floats, treating each array-of-float as
    // a (lng, lat, alt) coordinate and checks to see whether it is within
    // the map bounds. The nest array structure must be at least 2 deep, that
    // is the smallest acceptable structure is [[lng, lat, alt]].
    for (let cdx = 0; cdx < coords.length; ++cdx) {
      if (Array.isArray(coords[cdx][0])) {
        if (check_coords(coords[cdx])) {
          return true;
        }
      } else {
        // explicitly set the slice size because we can have (lng, lat,  alt) in
        // which case we end up with (alt, lat, lng) which latLng interprets
        // as (lat, lng), i.e. we end up with lat=alt lng=alt.
        let pt = L.latLng(coords[cdx].slice(0, 2).reverse());

        if (mapbounds.contains(pt)) {
          //console.log('...pt=', pt);
          //console.log('....pt in map');
          return true;
        }
      }
    }

    return false;
  }

  for (let fdx = 0; fdx < gjson.features.length; ++fdx) {
    let feature = gjson.features[fdx];

    let all_geometries;

    if (feature.geometry.coordinates === undefined) {
      all_geometries = feature.geometry.geometries;
    } else {
      all_geometries = [feature.geometry];
    }

    for (let gdx = 0; gdx < all_geometries.length; ++gdx) {
      let geometry = all_geometries[gdx];

      for (let cdx = 0; cdx < geometry.coordinates.length; ++cdx) {
        let coords = geometry.coordinates[cdx];
        if (check_coords([geometry.coordinates])) {
          return true;
        }
      }
    }
  }

  // convert to geojson layer for use with leaflet pip
  gjson = L.geoJSON(gjson);

  // if the map's bounding box corners are inside the polygon then it is still
  // visible to the user
  function pip(pt) {
    return leafletPip.pointInLayer(pt, gjson, true).length > 0;
  }

  if (pip(mapbounds.getNorthWest())) {
    return true;
  }
  if (pip(mapbounds.getSouthWest())) {
    return true;
  }
  if (pip(mapbounds.getNorthEast())) {
    return true;
  }
  if (pip(mapbounds.getSouthEast())) {
    return true;
  }

  return false;
}

/**
 * visible_layers: layers whose legend visibility will be updated. If not
 * given then all visible layers are checked.
 */
function update_layer_panels() {
  const {visible_layers} = g_vars;
  const visible_legends_html_vec = [];

  Object.values(visible_layers).forEach((layer) => {
    if (!layer_is_on_screen(layer)) {
      return;
    }

    // Update metadata
    const metadata_html = layer.bce_portal.metadata_html?.trim();   // jshint ignore:line
    if (!!metadata_html) {
      visible_legends_html_vec.push(metadata_html);
    }
  });

  show_html_in_panel(visible_legends_html_vec.join('<br/>'));
}

function get_field_photos(layer, finished_cb=undefined) {
  const render_options = layer.bce.render_options;

  if (!render_options.label_source_skai_feature_ids || !render_options.feature_class_id) {
    return;
  }

  const process_results = (result) => {
      for(const photo of result.results) {
        photo.layer = layer;
        g_vars.field_photos.push(photo)
      }
      show_field_photos();

      if (finished_cb !== undefined) {
        finished_cb();
      }
  }

  for (const skai_feature_id of render_options.label_source_skai_feature_ids) {
    $.ajax({
      url: `/api/feature-label-comment/?feature_label__feature_class=${render_options.feature_class_id}&feature_label__skai_features=${skai_feature_id}`,
      type: 'GET',
      success: process_results,
    });
  }
}

function remove_field_photos() {
  const field_photos_el = $('.field-photos');
  const map_el = $('#map');
  const field_photo_popup_el = $('.field-photos__popup');

  field_photos_el.removeClass('field-photos--active');
  map_el.removeClass('map--field-photos-active');
  field_photo_popup_el.html('');

  if (g_vars.field_photo_marker) {
    g_vars.map.removeLayer(g_vars.field_photo_marker);
  }
}

function init_field_photos() {
  const field_photos_el = $('.field-photos');
  const map_el = $('#map');
  const field_photo_popup_el = $('.field-photos__popup');

  field_photos_el.html('<div class="splide__track"><div class="splide__list"></div></div>');

  g_vars.field_photo_slider = new Splide('.field-photos', {
    pagination: false,
    perMove: 1,
    autoWidth: true,
  }).mount();

  field_photos_el.on('mouseenter', '.field-photos__slide', function(e) {
    const feature_class = $(this).find('img').data('feature-class');
    $(this).append($(`<div class="field-photos__tooltip">${feature_class}</div>`));
  });

  field_photos_el.on('mouseout', '.field-photos__slide', function(e) {
    $(this).find(".field-photos__tooltip").remove();
  });

  // Handle thumbnail clicks
  field_photos_el.on('click', '.field-photos__slide', function(e) {
    const img_el = $(this).find('img');
    const centroid = L.latLng(...img_el.data('center').split(',').reverse());

    if (g_vars.field_photo_marker) {
      g_vars.map.removeLayer(g_vars.field_photo_marker);
    }
    g_vars.field_photo_marker = L.marker(centroid);

    g_vars.field_photo_marker.addTo(g_vars.map);
    g_vars.map.flyTo(centroid, 23);

    field_photo_popup_el.addClass('field-photos__popup--active');
    field_photo_popup_el.html(`
      <button class="field-photos__popup-close btn btn-outline-light"><i class="fa fa-times"></i></button>
      <button class="field-photos__popup-focus btn btn-outline-light" data-center="${img_el.data('center')}"><i class="fa fa-map-marker-alt"></i></button>
      <img class="field-photos__popup-image" src="${img_el.data('preview')}" data-src="${img_el.data('full')}">
      <p class="field-photos__popup-caption"><strong class="field-photos__popup-caption-title">${img_el.data('feature-class')}</strong>${img_el.data('text')}</p>
    `);
  });

  // Handle popup re-focus button
  field_photo_popup_el.on('click', '.field-photos__popup-focus', function(e) {
    const centroid = L.latLng(...$(this).data('center').split(',').reverse());
    g_vars.map.flyTo(centroid, 23);
  });

  // Handle popup close button
  field_photo_popup_el.on('click', '.field-photos__popup-close', function(e) {
    field_photo_popup_el.html('');
    field_photo_popup_el.removeClass('field-photos__popup--active');
    g_vars.map.removeLayer(g_vars.field_photo_marker);
  });

  // Handle popup img clicks
  field_photo_popup_el.on('click', '.field-photos__popup-image', function(e) {
    window.open($(this).data('src'));
  });
}

function show_field_photos() {
  const field_photos_el = $('.field-photos');
  const map_el = $('#map');
  const html = [];

  if(!g_vars.field_photo_slider) {
    init_field_photos();
  }

  if(g_vars.field_photos.length === 0) {
    remove_field_photos();
    return;
  }

  const field_photos_list_el = $('.splide__list');

  for (const photo of g_vars.field_photos) {
    html.push(`<div class="field-photos__slide splide__slide" style="border-top: 5px solid ${photo.layer.bce.render_options.style.color}">
      <img class="field-photos__image" src="${photo.thumb}" data-feature-class="${photo.layer.bce.name}" data-preview="${photo.preview}" data-full="${photo.full}" data-text="${photo.text}" data-center="${photo.center.coordinates.join(',')}">
    </div>`);
  }

  field_photos_list_el.html(html.join(''));

  g_vars.field_photo_slider.refresh();

  field_photos_el.addClass('field-photos--active');
  map_el.addClass('map--field-photos-active');
}

/**
 * options:
 * - draw: bool, default true. If true drawing tools will be available
 * - navigation_toggle: default true, if false the toggle navigation
 *                      control will not be shown
 */
function setup_map(options) {
  let opt_defaults = {
    draw: true,
    navigation_toggle: true,
    contextmenu: true,
    enable_zoom: true,
    enable_dragging: true,
    enable_keyboard: true,
    geocoder: true,
  };

  options = $.extend(opt_defaults, options);

  // Default to canvas
  const renderer = platform_settings.renderer ?? 'canvas';  // jshint ignore:line
  /* global renderer */

  let map_options = {
    contextmenu: options.contextmenu,
    worldCopyJump: true,
    scrollWheelZoom: options.enable_zoom,
    zoomControl: options.enable_zoom,
    boxZoom: options.enable_zoom,
    doubleClickZoom: options.enable_zoom,
    touchZoom: options.enable_zoom,
    dragging: options.enable_dragging,
    keyboard: options.enable_keyboard,
    tap: options.contextmenu,
  }

  if(renderer === 'svg') {
    map_options.renderer = L.svg();
    map_options.preferCanvas = false;
  }
  else if(renderer === 'canvas') {
    map_options.renderer = L.canvas();
    map_options.preferCanvas = true;
  }

  let map = L.map('map', map_options);
  g_vars.map = map;

  setup_map_controls(map, options);
  setup_map_event_listeners(map, options);

  if (options.contextmenu) {
    setup_map_context_menu(map);
  }

  if(!platform_settings.demo_mode) {
    MapUtils.setup_basemaps(map, true);
  }

  // -------------------------------------------------
  // Set the initial view from URL params if available
  // -------------------------------------------------
  setup_from_url_params(map);

  if (g_vars.setup_did_set_map_view === false) {
    map.setView([-33.853411, 151.214990], 16);
    console.log('DEFAULT LATLNG');
  }

  return map;
}

/*
 * - annotation_type: str, default 'user'. If 'user' then the logged in user's
 *                annotations will be loaded. If 'shared' then the shared
 *                annotation will be loaded.
 */
function setup_annotations(annotation_type='user') {
  const {map, drawn_items} = g_vars;

  if (annotation_type === 'user') {
    restore_annotations(drawn_items);
  }

  if (annotation_type === 'shared') {
    load_shared_annotation(map, drawn_items);
  }

  add_annotation_controls(map, drawn_items);
}

function setup_map_controls(map, {enable_zoom, geocoder, navigation_toggle, recentre_button, draw}) {
  // --------------------
  // Add scale bar to map
  // --------------------

  L.control.scale().addTo(map);

  // XXX Note that the order in which geocoder and basemap controls is added to
  // the map affects their positioning

  // -------------------------------
  // Add geocoder control to the map
  // -------------------------------
  if (geocoder) {
    const geocoder = setup_geocoder_control(map);
    geocoder.addTo(map);
  }

  const sizing_btns = [];
  if (enable_zoom) {
    sizing_btns.push(setup_zoom_to_selection_btn());
  }

  // --------------------------------------------
  // Add toggle left navigation control to the map
  // --------------------------------------------
  if (navigation_toggle) {
    sizing_btns.push(setup_toggle_left_navigation_control(map));
  }

  if (recentre_button) {
    sizing_btns.push(setup_recentre_control());
  }

  if (sizing_btns.length) {
    L.easyBar(sizing_btns).setPosition('topright').addTo(map);
  }

  add_measure_tool();

  // -----------------------
  // Add Leaflet.draw to map
  // -----------------------
  let drawn_items = new L.FeatureGroup();
  g_vars.drawn_items = drawn_items;

  drawn_items.addTo(map);

  if (draw) {
    add_leaflet_draw(map, drawn_items);
  }
}

function setup_map_event_listeners(map, {enable_zoom, enable_dragging}) {
  if (enable_zoom) {
    map.on('zoomend', update_layer_panels);
  }

  if (enable_dragging) {
    map.on('moveend', update_layer_panels);
  }

  map.on('baselayerchange', layer => g_vars.current_basemap = layer);
  map.on('overlayadd', layer => {
    if (layer.name === MapUtils.IMAGERY_LABELS_KEY) {
      g_vars.basemap_labels_shown = true;
    }
  });
  map.on('overlayremove', layer => {
    if (layer.name === MapUtils.IMAGERY_LABELS_KEY) {
      g_vars.basemap_labels_shown = false;
    }
  });
}

function setup_from_url_params(map) {
  const url_params = new URLSearchParams(window.location.search);
  const fixed_view = url_params.get('fixed_view') === '1';
  const skai_features = (url_params.get('skai_features') || '').split(',').filter(id => !!id).map(id => +id);
  const basemap_name = decodeURIComponent(url_params.get('basemap') || '');
  const show_basemap_labels = +url_params.get('show_labels') === 1;
  const lat = parseFloat(url_params.get('lat'));
  const lng = parseFloat(url_params.get('lng'));
  let zoom = parseInt(url_params.get('z'));

  const setup_view = !isNaN(lat) && !isNaN(lng);
  if (setup_view) {
    // we ignore g_vars.setup_did_set_map_view b/c url params
    // always take precedence
    g_vars.setup_did_set_map_view = true;
    if (isNaN(zoom)) {
        zoom = 16;
    }
  }

  if (!!basemap_name || !!show_basemap_labels) {
    MapUtils.set_base_layers(map, basemap_name, show_basemap_labels);
  }

  // synchronise the setup to set view correctly
  new Promise(resolve => {
    if (fixed_view) {
        resolve(skai_features.map(load_and_render_layer));
    } else {
      // allow project tree to be setup first
      setTimeout(() => resolve(select_skai_feature_nodes(skai_features)), 100);
    }
  }).then(() => new Promise(resolve => {
    // layers are ordered as they are added to the map
    // which depends on the async return of skai_feature layer data from server
    // therefore after all the data are fetched, reorder the layers as requested
    reorder_skai_features(map, skai_features, resolve);
  })).then(() => {
    // there are few other init functions that try to set the map view
    // so we delay here to win the race to set map view
    if (setup_view) {
      setTimeout(() => map.setView([lat, lng], zoom), 1000);
    }
  });
}

function show_tree(tree) {
  tree = $(tree);
  tree.addClass('show');
  const panel = tree.parents('.sidebar-panel');
  panel.find('.sidebar-panel__expand').addClass('d-none');
  panel.find('.sidebar-panel__collapse').removeClass('d-none');
  panel.find('.sidebar-panel__controls-detailed').removeClass('d-none');
}

function hide_tree(tree) {
  tree = $(tree);
  tree.removeClass('show');
  const panel = tree.parents('.sidebar-panel');
  panel.find('.sidebar-panel__expand').removeClass('d-none');
  panel.find('.sidebar-panel__collapse').addClass('d-none');
  panel.find('.sidebar-panel__controls-detailed').addClass('d-none');
}

function select_skai_feature_nodes(skai_features) {
  // jshint ignore:start
  if (!skai_features?.length) {
    return;
  }
  // jshint ignore:end

  const tree_elems = $('.project-tree__tree').get();
  const trees = tree_elems.map(elem => {
    show_tree(elem);
    return $(elem).jstree(true);
  });

  const nodes_to_expand = tree_elems.map(() => []);
  tree_elems.map(elem => $(elem).jstree('open_all'));

  skai_features.forEach(id => {
    const selector = `[data-id='${id}'].skai-feature__node`
    for (let tree_idx=0; tree_idx<trees.length; tree_idx++) {
      const tree = trees[tree_idx];
      const node = tree.get_node(selector);
      if (node) {
        tree.select_node(node);
        nodes_to_expand[tree_idx].push(node.parents.reverse());
        break;
      }
    }
  });

  tree_elems.map(elem => $(elem).jstree('close_all'));

  for (let tree_idx=0; tree_idx<trees.length; tree_idx++) {
    const tree = trees[tree_idx];
    const nodes =  nodes_to_expand[tree_idx];
    if (nodes.length === 0) {
      hide_tree(tree_elems[tree_idx]);
    }
    nodes.forEach(nodes => nodes.forEach(node => tree.open_node(node)));
  }
}

function reorder_skai_features(map, ids, resolve) {
  // jshint ignore:start
  if (!ids?.length) {
    return resolve();
  }
  // jshint ignore:end
  const retry = () => reorder_skai_features(map, ids, resolve);
  const layers = Object.values(g_vars.visible_layers);
  const visible_ids = layers.map(layer => layer.bce.id);
  for (const id of ids) {
    if (!visible_ids.includes(id)) {
      setTimeout(retry, 1000);
      return;
    }
  }
  ids.forEach(id => {
    const layer = layers.find(({bce}) => bce.id === id);
    map.removeLayer(layer);
    map.addLayer(layer);
  });
  resolve();
}

function load_and_render_layer(id, options={}, finished_cb=undefined) {
  const url = `/portal/layer/${id}/json`;
  $.getJSON(url, function(data) {
    const leaflet_id = `${data.feature_type}.${data.id}`;
    let make_layer;

    switch(data.feature_type) {
      case types.RASTERIZED_LABELLER_VECTOR_TYPE:
        options.show_field_photos = true;
        make_layer = make_raster_layer;
        break;
      case types.RASTER_LAYER_TYPE:
        make_layer = make_raster_layer;
        break;
      case types.LABELLER_VECTOR_TYPE:
        options.show_field_photos = true;
        make_layer = make_vector_layer;
        break;
      case types.VECTOR_LAYER_TYPE:
        make_layer = make_vector_layer;
        break;
      case types.MAPPING_BOUNDARY:
        make_layer = make_vector_layer;
        break;
      case types.FEATURE_GROUP_TYPE:
        make_layer = make_grouped_layer;
        break;
      default:
        console.assert(false, 'Unknown node data type', data.feature_type);
    }

    if (make_layer) {
      show_layer(make_layer(data, leaflet_id), options, finished_cb);
    }
  });
}

function add_measure_tool() {
  const measure = L.control.measure({
    position: 'topright',
    activeColor: '#f069ff',
    completedColor: '#f069ff',
    primaryLengthUnits: ['centimeters', 'meters', 'kilometers'],
    primaryAreaUnits: ['sqmeters', 'hectares'],
    secondaryLengthUnits: [],
    secondaryAreaUnits: [],
    units: {
      centimeters: {
        factor: 100,
        display: 'Centimeters',
        decimals: 0,
        max: 100
      },
      meters: {
        factor: 1,
        display: 'Meters',
        decimals: 0,
        max: 1000
      },
      sqmeters: {
        factor: 1,
        display: 'Meters<sup>2</sup>',
        decimals: 2,
        max: 5000
      },
    }
  });

  const map = g_vars.map;
  measure.addTo(map);

  map.on('measurestart', () => {
    const draw_toolbars = document.querySelectorAll('.leaflet-draw-toolbar');
    draw_toolbars.forEach(toolbar => toolbar.classList.add('disabled'));
  });
  map.on('measurefinish', () => {
    const draw_toolbars = document.querySelectorAll('.leaflet-draw-toolbar');
    draw_toolbars.forEach(toolbar => toolbar.classList.remove('disabled'));
  });
}

function setup_zoom_to_selection_btn() {
  let map = g_vars.map;
  function start_zoom_selection() {
    $('.leaflet-container').css('cursor', 'crosshair');
    let zoom_selection = [];
    let tooltip = new L.Draw.Tooltip(map);
    let selection_rect = L.rectangle(map.getBounds());

    tooltip.updateContent({'text':'Select first corner.<br/>Press c to cancel.'});

    function end () {
      if (zoom_selection.length >= 2) {
        map.fitBounds(selection_rect.getBounds());
      }

      $('.leaflet-container').css('cursor', '');
      tooltip.dispose()
      map.off('mousemove', on_mouse_move);
      map.off('click', on_click);
      $(document).off('keydown', on_keydown);
      selection_rect.remove();

    }

    function on_mouse_move(e) {
      tooltip.updatePosition(e.latlng);
      if (zoom_selection.length > 0) {
        selection_rect.setBounds(L.latLngBounds(zoom_selection[0], e.latlng));
        selection_rect.addTo(map);
      }
    }

    function on_keydown(e) {
      if (e.originalEvent.key === 'c') {
        end();
      }
    }

    function on_click(e) {
      zoom_selection.push(e.latlng);
      if (zoom_selection.length === 1) {
        tooltip.updateContent({'text':'Select second corner.<br/>Press c to cancel'});
      } else if (zoom_selection.length >= 2) {
        end();
      }
    }
    map.on('mousemove', on_mouse_move);
    map.on('click', on_click);
    $(document).on('keydown', on_keydown);
  }

  const zoom_to_fit_btn = L.easyButton('far fa-vector-square', function() {
    start_zoom_selection();
  }, 'Zoom to fit selection.');

  return zoom_to_fit_btn;
}

function view_shared_annotation() {
  // disable annotation sharing, you can't re-share a shared annotation

  g_vars.annotation.sharing = false;
  g_vars.annotation.editing = false;

  setup_map({
    draw:false,
    navigation_toggle: false,
    recentre_button: true,
  });

  setup_annotations('shared');

  setup_global_hotkeys();

  feather.replace();
}

function show_selected_layers() {
  let params = new URLSearchParams(location.search);

  let rasters = params.getAll('raster');
  let vectors = params.getAll('vector');
  let features = params.getAll('class');

  rasters.forEach(function(layer_id) {
    let url = '/portal/layer/' + layer_id + '/json';
    let leaflet_id = types.RASTER_LAYER_TYPE + '.' + layer_id;
    $.getJSON(url, function(data) {
      show_layer(make_raster_layer(data, leaflet_id), {});
    });
  });
  vectors.forEach(function(layer_id) {
    let url = '/portal/layer/' + layer_id + '/json';
    let leaflet_id =  types.VECTOR_LAYER_TYPE + '.' + layer_id;
    $.getJSON(url, function(data) {
      show_layer(make_vector_layer(data, leaflet_id), {});
    });
  });
  features.forEach(function(layer_id) {
    rasters.forEach(function(raster_id) {
      let leaflet_id = types.RASTER_LAYER_TYPE + '.' + raster_id + '.with.class' + layer_id;
      let data = {
        render_url: '/labeller/layer/' + raster_id + '/class/' + layer_id + '/labels?format=geojson&include_field_photos=url',
        render_options: {
          file_type: 'geojson',
          centre_coordinates: [0, 0],
          style: {},
          show_feature_count: false,
          show_area: false
        },
        name: ''
      };
      show_layer(make_vector_layer(data, leaflet_id), {});
    });
  });
}

function start_access_token_updater() {
  let interval;

  let updater = function updater() {
    let now_ms = Date.now();

    if (document.visibilityState === 'hidden') {
      // when page is not visible, ignore checking tokens
      return;
    }

    MapUtils.refresh_basemap_access_tokens()
      .catch(err => {
        if (err instanceof FetchError) {
          console.error('Fetching access token failed');
          show_banner({type: 'danger', message: 'Failed to connect to server'}, true);
          clearInterval(interval);
        }
      });

    // check each layer's access tokens
    Object.keys(g_vars.visible_layers).forEach(function(k) {
      let layer = g_vars.visible_layers[k];
      if (!layer_access_expired(layer)) {
        return;
      }

      let url = '/portal/layer/' + layer.bce.id + '/json';
      $.getJSON(url)
        .done(function(data) {
          try {
            // leaflet does not provide any api to get hold of a layer instance
            // need to do the layer tracking manually and set options directly
            layer.options.access_token = data.access_token;
            layer.bce = data;
            layer.bce_portal.access_time_ms = now_ms;
          } catch (e) {
            // maybe the layer is not visible anymore
            console.error('failed to set access_token for', layer);
          }
        });
    });
  };

  // do an update now immediately
  updater();

  // then once every 5 seconds
  interval = setInterval(updater, 5 * 1000);
}

function layer_access_expired(layer) {
  let now_ms = +(new Date());
  // The access_expiry is cached server side which is shorter than the actual
  // expiry. Requesting earlier than this cached value will not regenerate the
  // access token but will provide the cached token. Hence, it is better to
  // actually request for the token when it expired.
  return (now_ms - layer.bce_portal.access_time_ms) >= layer.bce.access_expiry * 1000;
}

function view_selected_layers() {
  setup_map({
    draw: false,
    navigation_toggle: false,
    recentre_button: true,
  });

  setup_global_hotkeys();

  show_selected_layers();
  start_access_token_updater();

  feather.replace();
}

function setup() {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
  });

  $('.sidebar-panel__collapse,.sidebar-panel__expand').click(function() {
    const panel = $(this).parents('.sidebar-panel');
    panel.find('.sidebar-panel__collapse,.sidebar-panel__expand,.sidebar-panel__controls-detailed').toggleClass('d-none');
  });

  setup_project_tree('#active_project_tree', false);
  setup_project_tree('#archived_project_tree', true);
  setup_annotation_tree();
  setup_map();

  setup_global_hotkeys();

  start_access_token_updater();

  setup_annotations('user');

  // add icon to a.leaflet-draw-edit-{edit,remove}
  $('a.leaflet-draw-edit-edit').prepend('<span class="far fa-pencil"></span>');
  $('a.leaflet-draw-edit-remove').prepend('<span class="far fa-trash-alt"></span>');

  feather.replace();
}

function setup_fixed_view() {
  setup_map({
    draw: false,
    navigation_toggle: false,
    recentre_button: false,
    enable_zoom: false,
    enable_dragging: false,
    enable_keyboard: false,
    geocoder: false,
  });

  start_access_token_updater();

  feather.replace();
}

function show_tree_item_ids() {
  var show_project_tree_ids = JSON.parse(localStorage.getItem('skai-show-project-tree-ids') || 'false');
  if (show_project_tree_ids) {
    document.querySelectorAll('.tree-item-id').forEach(function(el) { el.classList.remove('d-none'); });
    document.querySelectorAll('.sidebar-panel__body').forEach(function(el) { el.style.overflow = 'scroll'; });
  }
}

function apply_package_tag_styles() {
    $('.package_badge').each((_, badge) => {
        const style_el = $(badge).find('[id^=id-package-tag-style]');
        const style = JSON.parse(style_el.text());
        $(badge).css(style);
    });
}

export { setup, setup_fixed_view, view_selected_layers, view_shared_annotation };
